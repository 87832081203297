import React, { Component } from 'react'
import Router from '../../costra/Router.js'
import WsContainers from '../../costra/WsContainers.js'
import Translate from '../../costra/Translate.js'

import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../../utils/ErrorPlaceholder.js'

import Groups from './unauthorized/Groups.js'
import Users from './unauthorized/Users.js'

class UnauthorizedEntities extends Component {

  constructor(props) {
    super(props)
    this.state = {
      roles: null,
      selected: (this.props.disableGroups ? 'ownership' : 'groups'),
      searchText: '',
      error: null,
    }
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.role.FetchProtectedRoles',
      _tenant: this.props.tenant,
    }).then((result) => {
      var keyed = {}
      if (result.items) {
        result.items.forEach((item) =>
          keyed[item._id.$oid] = item
        )
      }
      this.setState({
        roles: keyed,
      })
    }, () => {
      this.setState({error: 'error.load.roles'})
    })
  }

  componentDidMount() {
    this.load()
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder error={this.state.error}/>
    }
    if (!this.state.roles) {
      return <LoadingPlaceholder/>
    }

    return <div className="doubleList">
      <div className="header">

        <div className="icon authorization"/>
        <span>{Translate.get('entity.authorization.unauthorized')}</span>

        <select onChange={(event) => this.setState({selected: event.target.value})}
                value={this.state.selected}>
          {/*!this.props.disableGroups &&
            <option value="groups">
              {Translate.get('entity.authorization.groups')}
            </option>
          */}
          <option value="ownership">
            {Translate.get('entity.authorization.ownership')}
          </option>
          {Object.keys(this.state.roles).map((key) =>
            <option key={key} value={key}>
              {this.state.roles[key].name}
            </option>
          )}
        </select>

        <input type="text"
               title={Translate.get('entity.search')}
               placeholder={Translate.get('entity.search') + '...'}
               value={this.state.searchText}
               onChange={(event) => this.setState({searchText: event.target.value})}/>

        {this.state.roles[this.state.selected] &&
          <div className="button"
               title={Translate.get('role.edit')}
               onClick={() => Router.go('/roles/' + this.state.selected)}>
            <div className="icon edit"/>
          </div>
        }
      </div>

      {this.state.selected === 'groups' &&
        <Groups data={this.props.data}
                path={this.props.path}
                tenant={this.props.tenant}
                searchText={this.state.searchText}/>
      }
      {this.state.selected !== 'groups' &&
        <Users data={this.props.data}
               path={this.props.path}
               tenant={this.props.tenant}
               searchText={this.state.searchText}
               selectedRole={this.state.selected}/>
      }

    </div>
  }
}

export default UnauthorizedEntities