import React from 'react'
import { notification, Form, Button, Space } from 'antd'

import Translate from '../../costra/Translate.js'
import WsContainers from '../../costra/WsContainers.js'

import FormInput from '../../utils/FormInput.js'
import SimpleInput from '../../utils/SimpleInput.js'
import { notificationError, notificationSuccess } from '../../utils/Notification.js'

function Device(props) {

  const serviceIds = props.serviceIds.map(function(serviceId) {
    return {label: serviceId, value: serviceId}
  })

  switch(props.class) {
    case 'com.optimsys.costra.optimcall.device.SmsNeogateGateway':
      return <>
        <FormInput.Select data={props.data}
                          title="device.gateway.serviceId"
                          path="serviceId"
                          options={serviceIds}
                          disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.address"
                        path="address"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.port"
                        path="port"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.username"
                        path="username"
                        disabled={props.disabled}/>

        <FormInput.Password data={props.data}
                            title="device.gateway.password"
                            path="password"
                            disabled={props.disabled}/>

        <FormInput.Duration data={props.data}
                            title="device.gateway.reconnectTimeout"
                            path="reconnectTimeout"
                            disabled={props.disabled}/>


        <FormInput.Duration data={props.data}
                            title="device.gateway.partialMessageTimeout"
                            path="partialMessageTimeout"
                            disabled={props.disabled}/>

        <FormInput.Duration data={props.data}
                            title="device.gateway.maxPendingMessageAge"
                            path="maxPendingMessageAge"
                            disabled={props.disabled}/>

        <FormInput.Duration data={props.data}
                            title="device.gateway.interSubmitSmsDelay"
                            path="interSubmitSmsDelay"
                            disabled={props.disabled}/>
      </>

    case 'com.optimsys.costra.optimcall.device.SmsSmppGateway':
      const roles = [{
        title: Translate.get('device.gateway.role.server'),
        value: 'Server'
      }, {
        title: Translate.get('device.gateway.role.client'),
        value: 'Client'
      }]

      const encodings = [{
        title: 'UCS2',
        value: 'UCS2'
      }, {
        title: 'AlwaysASCII',
        value: 'AlwaysASCII'
      }, {
        title: 'ISO_8859_1',
        value: 'ISO_8859_1'
      }, {
        title: 'AlwaysISO_8859_1',
        value: 'AlwaysISO_8859_1'
      }, {
        title: 'AlwaysUCS2',
        value: 'AlwaysUCS2'
      }]

      const normalizers = [{
        title: Translate.get('device.gateway.normalizer.default'),
        value: {
          numberRegion: 'CZ',
          _class: 'com.optimsys.costra.sms.smpp.NumberNormalizer$Default'
        }
      }]

      return <>
        <FormInput.Select data={props.data}
                          title="device.gateway.serviceId"
                          path="serviceId"
                          options={serviceIds}
                          disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.address"
                        path="address.host"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.port"
                        path="address.port"
                        disabled={props.disabled}/>

        <FormInput.Select data={props.data}
                          title="device.gateway.role"
                          path="role"
                          options={roles}
                          disabled={props.disabled}/>

        <FormInput.Select data={props.data}
                          title="device.gateway.encoding"
                          path="encoding"
                          options={encodings}
                          disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.messageClass"
                        path="messageClass"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.serviceType"
                        path="serviceType"
                        disabled={props.disabled}/>

        <FormInput.Duration data={props.data}
                            title="device.gateway.interSubmitSmsDelay"
                            path="interSubmitSmsDelay"
                            disabled={props.disabled}/>

        <FormInput.Duration data={props.data}
                            title="device.gateway.reconnectTimeout"
                            path="reconnectTimeout"
                            disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.phoneNumberRegion"
                        path="phoneNumberRegion"
                        disabled={props.disabled}/>

        <FormInput.Select data={props.data}
                          title="device.gateway.normalizer"
                          path="gatewayNormalizer"
                          options={normalizers}
                          disabled={props.disabled}/>

        {props.data.get('gatewayNormalizer.numberRegion') &&
          <FormInput.Text data={props.data}
                          title="device.gateway.normalizer.numberRegion"
                          path="gatewayNormalizer.numberRegion"
                          disabled={props.disabled}/>
        }
      </>

    case 'com.optimsys.costra.optimcall.device.SmsSmsbranaGateway':
      const serviceModes = [{
        title: Translate.get('device.gateway.serviceMode.pull'),
        value: 'Pull'
      }, {
        title: Translate.get('device.gateway.serviceMode.push'),
        value: 'Push'
      }]

      return <>
        <FormInput.Select data={props.data}
                          title="device.gateway.serviceId"
                          path="serviceId"
                          options={serviceIds}
                          disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.address"
                        path="address"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.login"
                        path="login"
                        disabled={props.disabled}/>

        <FormInput.Password data={props.data}
                            title="device.gateway.password"
                            path="password"
                            disabled={props.disabled}/>

        <FormInput.Select data={props.data}
                          title="device.gateway.serviceMode"
                          path="serviceMode"
                          options={serviceModes}
                          disabled={props.disabled}/>

        <FormInput.Duration data={props.data}
                            title="device.gateway.checkCycleTimeout"
                            path="checkCycleTimeout"
                            disabled={props.disabled}/>
      </>

    case 'com.optimsys.costra.optimcall.device.SmsOdorikGateway':
      return <>
        <FormInput.Select data={props.data}
                          title="device.gateway.serviceId"
                          path="serviceId"
                          options={serviceIds}
                          disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.address"
                        path="address"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.gateway.login"
                        path="login"
                        disabled={props.disabled}/>

        <FormInput.Password data={props.data}
                            title="device.gateway.password"
                            path="password"
                            disabled={props.disabled}/>

        {props.data.get('serviceId') && props.storedLogin && props.storedPassword &&
          (props.storedLogin === props.data.get("login")) &&
          (props.storedPassword === props.data.get("password")) &&
          
          <Form.Item
            wrapperCol={{
              span: 8,
              offset: 4,
            }}
          >
            <Space>
              <Button
                onClick={() =>
                  WsContainers.action({
                    _class: 'com.optimsys.costra.optimcall.shiftplanner.device.odorik.GetCredit',
                    login: props.storedLogin,
                    serviceId: props.data.get('serviceId')
                  }).then((result) => {
                    notification.info({
                      message: Translate.get('device.credit'),
                      description: Translate.get('device.credit.odorik.crowns').replace("%amount%", result.credit),
                      duration: 5,
                      placement: 'bottomRight',
                    })
                  }, (error) => {
                    let reason = error.split(' ').slice(-1)[0]
                    let reasonMsg
                    if (reason) reasonMsg = Translate.get('device.error.' + reason)
                    notification.error({
                      message: Translate.get('device.credit.get.failed'),
                      description: reasonMsg,
                      duration: 5,
                      placement: 'bottomRight',
                    })
                  })
                }
              >
                {Translate.get("device.credit.get")}
              </Button>

              <Button
                onClick={() =>
                  WsContainers.action({
                    _class: 'com.optimsys.costra.optimcall.shiftplanner.device.odorik.GetAllowedSenders',
                    login: props.storedLogin,
                    serviceId: props.data.get('serviceId')
                  }).then((result) => {
                    notification.info({
                      message: Translate.get('device.allowedSenders'),
                      description: <code>
                        {Translate.get('device.allowedSenders.odorik.info')}:
                        <br/><br/>
                        {result.senders.map((sender, index) => <div key={'odorik.allowed.device' + index}>{sender}</div>)}
                      </code>,
                      duration: 0,
                      placement: 'bottomRight',
                    })
                  }, (error) => {
                    let reason = error.split(' ').slice(-1)[0]
                    let reasonMsg
                    if (reason) reasonMsg = Translate.get('device.error.' + reason)
                    notification.error({
                      message: Translate.get('device.allowedSenders.get.failed'),
                      description: reasonMsg,
                      duration: 10,
                      placement: 'bottomRight',
                    })
                  })
                }
              >
                {Translate.get("device.allowedSenders.get")}
              </Button>

              <Button
                onClick={() =>
                  WsContainers.action({
                    _class : "com.optimsys.costra.optimcall.shiftplanner.device.odorik.UpdateConnectionContext",
                  }).then(() => {
                    notificationSuccess('device.updateConnectionContext.success')
                  }, () => 
                    notificationError('device.updateConnectionContext.error')
                  )
                }
              >
                {Translate.get("device.updateConnectionContext")}
              </Button>
            </Space>
          </Form.Item>
        }
      </>

    case 'com.optimsys.costra.optimcall.fnb.lotus.FnbLotusDevice':
      return <>
        <FormInput.Text data={props.data}
                        title="device.fnb.lotus.uri"
                        path="uri"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.fnb.user"
                        path="userName"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.fnb.password"
                        path="password"
                        disabled={props.disabled}/>

        <FormInput.Duration data={props.data}
                            title="device.fnb.refreshTimeout"
                            path="refreshTimeout"
                            disabled={props.disabled}/>

        <ul className="list">
          {props.item.baseDn && props.item.baseDn.map((baseDn, index) =>
            <li key={index}>
              <div className="row">
                <div className="icon domain"/>
                <SimpleInput.Text data={props.data}
                                  title="device.fnb.lotus.baseDn"
                                  path={"baseDn." + index}
                                  disabled={props.disabled}
                                  className="size6"/>
                <div className="button"
                     title={Translate.get('remove.button')}
                     onClick={(event) => props.data.removeFromArray('baseDn', index)}>
                  <div className="icon remove"/>
                </div>
              </div>
            </li>
          )}
          <li>
            <div className="row withHover"
                 onClick={(event) => props.data.addToArray('baseDn', '')}>
              <div className="icon add"/>
              <div>{Translate.get('device.fnb.lotus.baseDn.add')}</div>
            </div>
          </li>
        </ul>
      </>


    case 'com.optimsys.costra.optimcall.fnb.micromedic.FnbMicromedicDevice':
      return <>
        <FormInput.Text data={props.data}
                        title="device.fnb.micromedic.uri"
                        path="uri"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.fnb.user"
                        path="userName"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.fnb.password"
                        path="password"
                        disabled={props.disabled}/>

        <FormInput.Duration data={props.data}
                            title="device.fnb.refreshTimeout"
                            path="refreshTimeout"
                            disabled={props.disabled}/>
      </>

    case 'com.optimsys.costra.optimcall.device.MailSmtpProvider':
      return <>
        <FormInput.Text data={props.data}
                        title="device.mail.smpt.sender.address"
                        path="senderAddress"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.mail.smpt.host"
                        path="host"
                        disabled={props.disabled}/>


        <FormInput.Text data={props.data}
                        title="device.mail.smpt.port"
                        path="port"
                        disabled={props.disabled}/>

        <FormInput.Text data={props.data}
                        title="device.mail.smpt.user"
                        path="user"
                        disabled={props.disabled}/>

        <FormInput.Password data={props.data}
                            title="device.mail.smpt.password"
                            path="password"
                            disabled={props.disabled}/>

        <FormInput.Check data={props.data}
                         title="device.mail.smpt.useStartTls"
                         path="useStartTls"
                         disabled={props.disabled}/>

        <FormInput.Check data={props.data}
                         title="device.mail.smpt.plainAuth"
                         path="plainAuth"
                         disabled={props.disabled}/>

        <FormInput.Check data={props.data}
                         title="device.mail.smpt.useSsl"
                         path="useSsl"
                         disabled={props.disabled}/>

        <FormInput.Check data={props.data}
                         title="device.mail.smpt.debug"
                         path="debug"
                         disabled={props.disabled}/>

      </>

    default:
      return <></>
  }
}

export default Device