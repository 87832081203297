import React, { Component } from 'react'
import Router from '../../costra/Router.js'
import Dialogs from '../../costra/Dialogs.js'
import Translate from '../../costra/Translate.js'
import SimpleListContainer from '../../costra/SimpleListContainer.js'

import ErrorPlaceholder from '../../utils/ErrorPlaceholder.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import ListControls from '../../utils/ListControls.js'
import InfoBox from '../../utils/InfoBox.js'
import SearchContainer from '../../utils/SearchContainer.js'

import RemoveApiKeyDialog from './RemoveApiKeyDialog.js'

import '../../css/list.css'

class ApiKeys extends Component {

  constructor(props) {
    super(props)
    var state =  SimpleListContainer.initialState()
    state.itemsPerPage = 50
    state.searchedText = ''
    this.state = state
    this.container = new SearchContainer(this, 'com.optimsys.costra.optimcall.shiftplanner.api.ApiKeys', () => {
      this.setState({error: 'error.load.api.keys'})
    })
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  removeItem(item, event) {
    event.stopPropagation()
    Dialogs.open(<RemoveApiKeyDialog item={item}/>)
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder error={this.state.error}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <div className="content">
      <h1>
        <div className="icon right"/>
        <span>{Translate.get('api.key.section')}</span>
      </h1>

      {this.state.noTenant &&
        <InfoBox text="tenant.notSelected.info"/>
      }

      <ul className="list">
        {this.state.items && this.state.items.map((item) =>
          <li key={item._id.$oid}>
            <div className="row withHover"
                 onClick={(event) => Router.go('/keys/' + item._id.$oid)}>
              <div className="icon right"/>
              <div>{item.displayName}</div>
              <div className="button right"
                   title={Translate.get('remove.button')}
                   onClick={(event) => this.removeItem(item, event)}>
                <div className="icon remove"/>
              </div>
            </div>
          </li>
        )}
      </ul>

      <ListControls component={this} container={this.container} withSearch={true}>
        {this.state.allowAdd && 
          <div className="button left"
              title={Translate.get('api.key.create.button')}
              onClick={(event) => Router.go('/keys/new')}>
            <div className="icon add"/>
          </div>
        }
      </ListControls>

    </div>
  }
}

export default ApiKeys