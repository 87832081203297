import React from 'react'
import { PageHeader, Button, Tabs } from 'antd'
import { RollbackOutlined, CheckOutlined, SaveOutlined } from '@ant-design/icons'

import Translate from '../costra/Translate.js'
import Router from '../costra/Router.js'

function EditPageHeader(props) {
  return (
    <PageHeader
      avatar={{ icon: <div className={'icon big ' + props.icon} />, style: { backgroundColor: 'white' } }}
      style={{ backgroundColor: 'white', borderBottom: '1px solid lightgray' }}
      title={props.component.props.id !== 'new' ? (props.title || Translate.get(props.titleUnnamed)) : Translate.get(props.titleCreate)}
      onBack={() => Router.go(props.closePath)}
      extra={[
        !props.disabled && <Button key="revert" icon={<RollbackOutlined />} onClick={props.component.load}>{Translate.get('revert.button')}</Button>,
        !props.disabled && <Button key="save" icon={<SaveOutlined />} onClick={props.component.apply}>{Translate.get('save.button')}</Button>,
        !props.disabled &&
        <Button
          key="saveAndClose"
          icon={<CheckOutlined />}
          type="primary"
          onClick={() => props.component.apply().then(() => Router.go(props.closePath))}
        >
          {Translate.get('saveAndClose.button')}
        </Button>,
      ]}
      footer={props.tabs && props.tabs.length > 0 &&
        <Tabs activeKey={props.component.state.activeTab} onChange={(key) => props.component.setState({ activeTab: key })}>
          {props.tabs.map(tab =>
            <Tabs.TabPane key={tab.key} tab={Translate.get(tab.title)} />
          )}
        </Tabs>
      }
    />
  )
}

export default EditPageHeader