import React from 'react'
import { Form, Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import Translate from '../../costra/Translate.js'

import Port from './Port.js'

class Ports {

  constructor(component) {
    this.component = component
    this.addDevice = this.addDevice.bind(this)
    this.removeDevice = this.removeDevice.bind(this)
    this.changeValue = this.changeValue.bind(this)
  }

  prepareEmptyDevice(id, tenant, parentClass) {
    switch(parentClass) {
      case 'com.optimsys.costra.optimcall.device.SmsNeogateGateway':
        return {
          gatewayId: id,
          isEnabled: true,
          displayName: '',
          sender: '',
          span: 0,
          _tenant: tenant,
          _class: 'com.optimsys.costra.optimcall.device.SmsNeogatePort',
        }

      case 'com.optimsys.costra.optimcall.device.SmsSmppGateway':
        return {
          gatewayId: id,
          isEnabled: true,
          displayName: '',
          sender: '',
          systemId: '',
          password: '',
          _tenant: tenant,
          _class: 'com.optimsys.costra.optimcall.device.SmsSmppPort',
        }

      case 'com.optimsys.costra.optimcall.device.SmsSmsbranaGateway':
        return {
          gatewayId: id,
          isEnabled: true,
          displayName: '',
          sender: '',
          _tenant: tenant,
          _class: 'com.optimsys.costra.optimcall.device.SmsSmsbranaPort',
        }

      case 'com.optimsys.costra.optimcall.device.SmsOdorikGateway':
        return {
          gatewayId: id,
          isEnabled: true,
          displayName: '',
          sender: '',
          _tenant: tenant,
          _class: 'com.optimsys.costra.optimcall.device.SmsOdorikPort',
        }

      default:
        return {}
    }
  }

  addDevice(id, tenant, parentClass, component) {
    component.setState(oldState => {
      let ports = oldState.ports
      ports.push(this.prepareEmptyDevice(id, tenant, parentClass))
      return {
        ports: ports
      }
    })
  }

  removeDevice(index, component) {
    component.setState(oldState => {
      let ports = oldState.ports
      ports.splice(index, 1)
      return {
        ports : ports,
      }
    })
  }

  changeValue(index, valueKey, value, component) {
    component.setState(oldState => {
      let ports = oldState.ports
      ports[index][valueKey] = value
      return {
        ports: ports
      }
    })
  }

  render(disabled) {
    return <div className="content">
      <ul className="list">
        {this.component.state.ports && this.component.state.ports.map((device, index) =>
          <li key={index}>

            <Port item={device}
                  index={index}
                  changeValue={this.changeValue}
                  component={this.component}
                  removePort={!disabled && 
                    <Form.Item
                      wrapperCol={{
                        span: 8,
                        offset: 4
                      }}
                    >
                      <Button
                        onClick={() => this.removeDevice(index, this.component)}
                      >
                        {Translate.get("remove.button")}
                      </Button>
                    </Form.Item>
                  }
                  disabled={disabled}
            />
            <Divider/>
          </li>
        )}
        <br/>
        {!disabled &&
          <Form.Item
            wrapperCol={{
              span: 8,
              offset: 4,
            }}
          >
            <Button 
              type="dashed"
              onClick={() => 
                this.addDevice(
                  this.component.state.item._id,
                  this.component.state.item._tenant,
                  this.component.state.item._class,
                  this.component
                )
              }
              block
              icon={<PlusOutlined/>}
            >
              {Translate.get('device.add.port')}
            </Button>
          </Form.Item>
        }
      </ul>
    </div>

  }
}

export default Ports