import React from 'react'

import AuthorizedEntities from './authorization/AuthorizedEntities.js'
import UnauthorizedEntities from './authorization/UnauthorizedEntities.js'

function AuthorizationMembership(props) {
  return (
    <div className="doubleListContainer">
      <AuthorizedEntities data={props.data} path={props.path} disabled={props.disabled} tenant={props.tenant}/>
      {!props.disabled &&
        <UnauthorizedEntities data={props.data} path={props.path} disableGroups={props.disableGroups} tenant={props.tenant}/>
      }
    </div>
  )
}

export default AuthorizationMembership