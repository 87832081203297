import React from 'react'
import { format, addDays, subDays, getWeek, startOfWeek, endOfWeek, startOfMonth, endOfMonth, isSameDay, isAfter, getDayOfYear, getDate, getMonth } from 'date-fns'
import { Select, Switch } from 'antd'

import Translate from '../costra/Translate.js'
import SimpleListContainer from '../costra/SimpleListContainer.js'
import WsContainers from '../costra/WsContainers.js'

import "../css/calendar.css";
import { notificationError, notificationInfo } from '../utils/Notification.js'

class Container extends SimpleListContainer {
  params(state) {
    const monthStart = startOfMonth(this.component.props.currentMonth ? this.component.props.currentMonth : new Date());
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    var params = super.params()
    params['startTime'] = startDate.getTime()
    params['endTime'] = endDate.getTime()
    params['calendar'] = this.component.props.selectedCalendar._id.$oid
    return params
  }
}

class Calendar extends React.Component {
  constructor(props) {
    super(props)

    var state = SimpleListContainer.initialState()
    state.selectedDate = new Date()
    state.isBulkEditingOn = false
    state.selectedCalendar = this.props.selectedCalendar
    state.assigneeOfBulkEditing = null
    state.publicHolidays = null
    state.currentMonth = props.currentMonth

    state.popoverVisibleDay = null
    state.popoverSelectedAssignee = null
    state.popoverSelectedPosition = null

    state.maxDayPosition = 2

    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.optimcall.shiftplanner.calendar.ShiftCalendar', () => {
      this.setState({ error: 'error.load.shiftCalendar' })
    })
    this.myRef = React.createRef()

    this.systemId = "000000000000000000000000"
  }

  componentDidMount() {
    this.container.reload()

    if (this.props.isMobile) {
      this.executeScroll()
    }
  }

  componentDidUpdate() {
    if (this.props.selectedCalendar._id.$oid !== this.state.selectedCalendar._id.$oid
      || this.props.currentMonth !== this.state.currentMonth) {

      this.setState({
        selectedCalendar: this.props.selectedCalendar,
        currentMonth: this.props.currentMonth,
        isBulkEditingOn: false,
      }, () => {
        this.container.reload()
      })
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  setShift(assignee, assigneePosition, calendarId, dayOfYearStart, yearStart) {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.shift.StoreShift',
      assignee: assignee,
      assigneePosition: assigneePosition,
      calendar: calendarId,
      dayOfYearStart: dayOfYearStart,
      yearStart: yearStart,
    }).then(() => {
      if (assignee === this.systemId) {
        notificationInfo('shift.unset')
      }
      else {
        notificationInfo('shift.set')
      }
    }, () => {
      notificationError('failed.set.shift')
    })
  }

  deleteShift(shift, calendar) {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.shift.RemoveShift',
      shift: shift,
      calendar: calendar,
    }).then(() => {
      notificationInfo('shift.removed')
    }, () => {
      notificationError('error.shift.remove')
    })
  }

  /* 
  not used since shift position was introduced
  getUserNameForDay(day) {
    let result = ""

    if (!this.state.shifts) {
      return ""
    }

    let getShift = this.state.shifts.find(shift => shift.startTimeExt.dayOfMonth === day.getDate() && shift.startTimeExt.monthOfYear === day.getMonth() + 1)
    if (getShift) {
      let getUserOfShift = getShift.assignee.$oid
      if (getUserOfShift === this.systemId) {
        return ""
      }
      else if (this.props.workers.find(worker => worker.value === getUserOfShift)) {
        result = this.props.workers.find(worker => worker.value === getUserOfShift).label
      } else {
        result = Translate.get('error.user.notExisting')
      }
    }

    return result
  } */

  getShiftForDay(day) {
    return this.state.shifts?.find(shift => shift.startTimeExt.dayOfMonth === day.getDate() && shift.startTimeExt.monthOfYear === day.getMonth() + 1)
  }

  getUserNameForShift(shift, assigneePosition) {
    const assigneeId = shift.assignees?.find(assignee => assignee.position === assigneePosition)?.id.$oid
    return this.props.workers.find(worker => worker.value === assigneeId)?.label || ''
  }

  onChangeShift(day, value, position) {
    if (this.state.allowedModifyShifts) {
      let calendar = this.props.selectedCalendar._id.$oid
      this.setShift(value, position, calendar, getDayOfYear(day), day.getFullYear())
    }
  }

  getAdditionalShiftsForTheDay(day) {
    let result = this.state.shifts ? this.state.shifts.filter(shift => isSameDay(shift.startTime, day)) : []
    if (result.length > 0) {
      result = result.slice(1)
    }
    return result
  }

  removeSelect(array, x) {
    array.splice(array.indexOf(x), 1)
    return array
  }

  renderDays() {
    const daysOfWeek = Translate.get('week.days')
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {daysOfWeek[i]}
        </div>
      );
    }
    return <div className="days row">
      <div className="week" />
      {days}
    </div>;
  }

  isPublicHoliday(date) {
    if (this.state.publicHolidays) {
      const year = date.getFullYear()

      const dateInt = this.dayOfYearInt(getMonth(date) + 1, getDate(date))

      if (this.state.publicHolidays.fixedIntervals && this.state.publicHolidays.fixedIntervals.find(interval =>
        dateInt >= this.dayOfYearInt(interval.from.monthOfYear, interval.from.dayOfMonth) &&
         dateInt <= this.dayOfYearInt(interval.to.monthOfYear, interval.to.dayOfMonth)
      )) {
        return true
      }

      if (this.state.publicHolidays.floatIntervals && this.state.publicHolidays.floatIntervals.find(interval => 
        year === interval.from.year && year === interval.to.year && 
          dateInt >= this.dayOfYearInt(interval.from.monthOfYear, interval.from.dayOfMonth) && 
          dateInt <= this.dayOfYearInt(interval.to.monthOfYear, interval.to.dayOfMonth)
      )) {
        return true
      }
    }
    return false
  }

  dayOfYearInt(monthOfYear, dayOfMonth) {
    return monthOfYear * 100 + dayOfMonth
  }

  renderCells() {
    const { selectedDate } = this.state;
    const monthStart = startOfMonth(this.props.currentMonth)
    const monthEnd = endOfMonth(monthStart)
    let startDate = startOfWeek(monthStart, { weekStartsOn: 1 })
    let endDate = endOfWeek(monthEnd, { weekStartsOn: 1 })
    if (this.props.isMobile) {
      startDate = monthStart
      endDate = monthEnd
    }
    else {
      startDate = startOfWeek(monthStart, { weekStartsOn: 1 })
      endDate = endOfWeek(monthEnd, { weekStartsOn: 1 })
    }

    const dateFormat = "dd.MM.yyyy";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    if (this.props.isMobile) {
      while (day <= endDate) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const shift = this.getShiftForDay(cloneDay)
        const workersPerShift = this.props.selectedCalendar && this.props.selectedCalendar.numberOfWorkersPerShift ? this.props.selectedCalendar.numberOfWorkersPerShift : 1

        rows.push(
          <div
            className={"mobile-row cell" + (
              !isAfter(day, subDays(new Date(), 1))
                ? " disabled"
                : (isSameDay(day, selectedDate) ? ' selected' : '') +
                (this.isPublicHoliday(day) ? ' holiday' : '') +
                ((day.getDay() === 6 || day.getDay() === 0) ? ' weekend' : '') +
                (day.getMonth() !== this.props.currentMonth.getMonth() ? ' faded' : '')
            )}
            key={day}
            onClick={(event) => {
              if (this.state.isBulkEditingOn && this.state.allowedModifyShifts) {
                if (this.state.assigneeOfBulkEditing) {
                  if (this.state.positionOfBulkEditing) {
                    this.onChangeShift(cloneDay, this.state.assigneeOfBulkEditing, this.state.positionOfBulkEditing)
                  }
                  else {
                    if (workersPerShift === 1) {
                      this.onChangeShift(cloneDay, this.state.assigneeOfBulkEditing, 1)
                    } else {
                      notificationError('error.bulk.noPosition')
                    }
                  }
                }
                else {
                  notificationError('error.bulk.noAssignee')
                }
              }
              /*else {
                this.setState({ showDayDetail: cloneDay })
              }*/
            }}
            ref={isSameDay(day, selectedDate) ? this.myRef : ""}
          >
            <div className="info">
              <span className="number">{Translate.get('week.days')[cloneDay.getDay() === 0 ? 6 : cloneDay.getDay() - 1]} {formattedDate}</span>
              {!this.state.isBulkEditingOn && this.state.allowedModifyShifts && <div className="selects">
                {Array.from({ length: this.props.selectedCalendar.numberOfWorkersPerShift }, (_, i) => i + 1).map(position =>
                  <Select
                    key={position}
                    value={shift ? this.getUserNameForShift(shift, position) : ''}
                    onChange={(value) => this.onChangeShift(cloneDay, value, position)}
                    onClick={(event) => { event.stopPropagation(); event.preventDefault() }}
                    options={this.props.workers}
                  />
                )}
              </div>
              }

              {(this.state.isBulkEditingOn || !this.state.allowedModifyShifts) &&
                <div className="bulk-name">
                  {workersPerShift > 1 ?
                    shift ? Array.from({ length: workersPerShift }, (_, i) => i + 1).map(position => {
                      let name = this.getUserNameForShift(shift, position)
                      return name ? <div key={position}>{position}. {name}</div> : <></>
                    }) : ' '
                    : shift ? this.getUserNameForShift(shift, 1) : ''
                  }
                </div>
              }

              <span className="bg">{format(day, "dd")}</span>
            </div>
            {shift && isAfter(cloneDay, subDays(new Date(), 1)) && this.props.selectedCalendar.numberOfWorkersPerShift > 1 && this.state.allowedModifyShifts &&
              <div className="button-container">
                <div className="button-clear" onClick={(e) => { this.deleteShift(shift._id, shift.calendar) }}>
                  {Translate.get('shift.delete')}
                </div>
              </div>
            }

          </div >
        );
        day = addDays(day, 1);
      }
    }
    else {
      while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
          formattedDate = format(day, dateFormat);
          const cloneDay = day;
          const shift = this.getShiftForDay(cloneDay)
          const workersPerShift = this.props.selectedCalendar && this.props.selectedCalendar.numberOfWorkersPerShift ? this.props.selectedCalendar.numberOfWorkersPerShift : 1
          days.push(
            <div
              className={"col cell" + (
                !isAfter(day, subDays(new Date(), 1))
                  ? " disabled"
                  : (isSameDay(day, selectedDate) ? " selected" : "") +
                  (this.isPublicHoliday(day) ? " holiday" : "") +
                  ((day.getDay() === 6 || day.getDay() === 0) ? ' weekend' : '') +
                  (day.getMonth() !== this.props.currentMonth.getMonth() ? ' faded' : '')
              )}
              key={day}
              onClick={() => {
                if (this.state.isBulkEditingOn && this.state.allowedModifyShifts) {
                  if (this.state.assigneeOfBulkEditing) {
                    if (this.state.positionOfBulkEditing) {
                      this.onChangeShift(cloneDay, this.state.assigneeOfBulkEditing, this.state.positionOfBulkEditing)
                    }
                    else {
                      if (workersPerShift === 1) {
                        this.onChangeShift(cloneDay, this.state.assigneeOfBulkEditing, 1)
                      } else {
                        notificationError('error.bulk.noPosition')
                      }
                    }
                  }
                  else {
                    notificationError('error.bulk.noAssignee')
                  }
                }
                /*else {
                  this.setState({ showDayDetail: cloneDay })
                }*/
              }}
            >
              <span className="number">{formattedDate}</span>
              {!this.state.isBulkEditingOn && this.state.allowedModifyShifts && <div className="selects">
                {Array.from({ length: this.props.selectedCalendar.numberOfWorkersPerShift }, (_, i) => i + 1).map(position =>
                  <Select
                    key={position}
                    value={shift ? this.getUserNameForShift(shift, position) : ''}
                    onChange={(value) => this.onChangeShift(cloneDay, value, position)}
                    onClick={(event) => { event.stopPropagation(); event.preventDefault() }}
                    options={this.props.workers}
                  />
                )}
              </div>
              }

              {(this.state.isBulkEditingOn || !this.state.allowedModifyShifts) &&
                <div className="bulk-name">
                  {workersPerShift > 1 ?
                    shift ? Array.from({ length: workersPerShift }, (_, i) => i + 1).map(position => {
                      let name = this.getUserNameForShift(shift, position)
                      return name ? <div key={position}>{position}. {name}</div> : <></>
                    }) : ' '
                    : shift ? this.getUserNameForShift(shift, 1) : ''
                  }
                </div>
              }

              <span className="bg">{format(day, "dd")}</span>

              {shift &&
                shift.assignees &&
                isAfter(cloneDay, subDays(new Date(), 1)) &&
                this.props.selectedCalendar.numberOfWorkersPerShift > 1 &&
                this.state.allowedModifyShifts &&

                <div className="button-clear" onClick={(e) => { this.deleteShift(shift._id, shift.calendar) }}>
                  {Translate.get('shift.delete')}
                </div>
              }
            </div >
          );
          day = addDays(day, 1);
        }
        rows.push(
          <div className="row" key={day}>
            <div className="week">{getWeek(subDays(day, 7), { firstWeekContainsDate: 1 }, { weekStartsOn: 1 })}</div>
            {days}
          </div>
        );
        days = [];
      }
    }

    return <div className="body">{rows}</div>;
  }

  onDateClick = day => {
    this.setState({
      selectedDate: day
    });
  };

  executeScroll = () => this.myRef.current.scrollIntoView()

  showDayDetail = (day) => {
    const hours = []
    const dateFormat = "dd.MM.yyyy";
    const shiftRanges = [1, 2, 3, 4, 6, 8, 12]

    for (let i = 0; i < 24; i += this.state.shiftRange) {
      hours.push(
        <div
          className="hour"
          key={i}
        >
          <span className="number">{i}:00 - {this.state.shiftRange === 1 ? i : i + this.state.shiftRange - 1}:59</span>
          {!this.state.isBulkEditingOn &&
            <Select
              onChange={(value) => this.onChangeShift(day, value)}
              value={this.getUserNameForShift(day)}
              options={this.props.workers}
            />
          }
        </div >
      );
    }

    return <div className="mask">
      <div className="dialog">
        <div className="hour-header">
          <h1 className="unselectable">{format(day, dateFormat)}</h1>
          <div className="decline" onClick={() => this.setState({ showDayDetail: null })}>
            <svg height="365pt" viewBox="0 0 365.696 365.696" width="365pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
            </svg>
          </div>
        </div>
        <div className="shifts-ranges">
          <div className="label">{Translate.get('shift.range')}</div>
          {shiftRanges.map((item, i) => {
            return <div className={"range" + (this.state.shiftRange === item ? " selected" : "")} key={i} onClick={() => this.setState({ shiftRange: item })}>
              {item}
            </div>
          })}

        </div>
        <div className="hours-of-day">{hours}</div>
      </div>
    </div>;
  }

  renderBulkEditing() {
    let workersPerShift = this.props.selectedCalendar && this.props.selectedCalendar.numberOfWorkersPerShift ? this.props.selectedCalendar.numberOfWorkersPerShift : 1
    return <div className="bulk-editing">
      {this.state.isBulkEditingOn && workersPerShift > 1 &&
        <Select
          onChange={(value) => this.setState({ positionOfBulkEditing: value })}
          defaultValue={Translate.get('shiftplanner.bulk.position')}
        >
          {Array.from({ length: this.props.selectedCalendar.numberOfWorkersPerShift }, (_, i) => i + 1).map(position => {
            return <Select.Option key={position} value={position}>{position}</Select.Option>
          })}
        </Select>
      }
      {this.state.isBulkEditingOn &&
        <Select
          onChange={(value) => this.setState({ assigneeOfBulkEditing: value })}
          defaultValue={Translate.get('shiftplanner.bulk.user')}
          options={this.props.workers}
        />
      }
      <Switch
        checkedChildren={Translate.get('shiftplanner.bulk.on')}
        unCheckedChildren={Translate.get('shiftplanner.bulk.off')}
        checked={this.state.isBulkEditingOn}
        onChange={(value) => this.setState({ isBulkEditingOn: value, assigneeOfBulkEditing: null })} />
    </div>
  }

  render() {
    return (<>
      <div className="calendar">
        {!this.props.isMobile && this.renderDays()}
        {this.renderCells()}
        {/*this.state.showDayDetail && this.showDayDetail(this.state.showDayDetail)*/}
      </div>

      {this.state.allowedModifyShifts && this.renderBulkEditing()}
    </>
    );
  }
}

export default Calendar