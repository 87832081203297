import React, { Component } from 'react'
import Router from '../../costra/Router.js'
import Dialogs from '../../costra/Dialogs.js'
import Translate from '../../costra/Translate.js'
import SimpleListContainer from '../../costra/SimpleListContainer.js'

import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import ListControls from '../../utils/ListControls.js'
import InfoBox from '../../utils/InfoBox.js'
import SearchContainer from '../../utils/SearchContainer.js'

import RemoveDeviceDialog from './RemoveDeviceDialog.js'

import '../../css/list.css'

class Devices extends Component {

  constructor(props) {
    super(props)
    var state =  SimpleListContainer.initialState()
    state.itemsPerPage = 50
    state.searchedText = ''
    this.state = state
    this.container = new SearchContainer(this, 'com.optimsys.costra.optimcall.shiftplanner.device.Devices', () => {
      this.setState({error: "error.load.devices"})
    })
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  removeItem(item, event) {
    event.stopPropagation()
    Dialogs.open(<RemoveDeviceDialog item={item}/>)
  }

  render() {
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <div className="content">
      <h1>
        <div className="icon device"/>
        <span>{Translate.get('device.section')}</span>
      </h1>

      {this.state.noTenant &&
        <InfoBox text="tenant.notSelected.info"/>
      }

      <ul className="list">
        {this.state.items && this.state.items.map((item) =>
          <li key={item._id.$oid}>
            <div className="row withHover"
                 onClick={(event) => Router.go('/devices/' + item._id.$oid)}>
              <div className="icon device"/>
              <div>{item.displayName}</div>
              {item._authorization.DeviceUpRem &&
                <div className="button right"
                     title={Translate.get('remove.button')}
                     onClick={(event) => this.removeItem(item, event)}>
                  <div className="icon remove"/>
                </div>
              }
            </div>
          </li>
        )}
      </ul>

      <ListControls component={this} container={this.container} withSearch={true}>
        {this.state.allowAdd &&
          <div className="button left"
               title={Translate.get('device.create.button')}
               onClick={(event) => Router.go('/devices/new')}>
            <div className="icon add"/>
          </div>
        }
      </ListControls>

    </div>
  }
}

export default Devices
