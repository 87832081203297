import React, { Component } from 'react'
import { Form } from 'antd'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'

import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormSection from '../../utils/FormSection.js'
import EditControls from '../../utils/EditControls.js'
import FormInput from '../../utils/FormInput.js'
import { notificationError, notificationSuccess } from '../../utils/Notification.js'

class ApiKeyEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      expandedSections: {
        apiKey: true,
      },
    }

    this.data = new EditData(this, 'item.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)
  }

  load() {
    this.setState({ item: null })
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.api.FetchApiKey',
      id: (this.props.id === 'new' ? undefined : this.props.id),
    }).then((result) => {
      this.setState({item: result})
    }, () => {
      notificationError('error.load.api.key')
      Router.go('/keys')
    })
  }

  apply() {
    var item = this.state.item
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.api.UpdateApiKey',
      item: item,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({item: result})
      if (this.props.id !== result._id.$oid) {
        Router.go('/keys/' + result._id.$oid)
      }
    }, () => {
      notificationError('error.store.api.key')
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.id !== this.props.id) {
      this.load()
    }
  }

  render() {
    if (!this.state.item) {
      return <div className="content"><LoadingPlaceholder/></div>
    }

    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 8,
      },
    }

    return <div>
      <div className="content">

        <FormSection name="apiKey"
                     title="api.key.title"
                     icon="right"
                     component={this}>
          <div className="content">
            <Form {...formItemLayout}>
              <FormInput.Text data={this.data}
                              title="api.key.displayName"
                              path="displayName"/>

              <FormInput.Text data={this.data}
                              title="api.key.description"
                              path="description"/>

              <FormInput.Text data={this.data}
                              title="api.key.key"
                              path="key"/>
            </Form>

          </div>
        </FormSection>

      </div>
      <EditControls component={this}
                    returnPath="/keys"
                    title={this.state.item.displayName}
                    icon="right"/>
    </div>
  }

}

export default ApiKeyEdit