import React from 'react'
import Translate from '../costra/Translate.js'

function InfoBox(props) {
  return (
    <div className="infoBox">
      <div className="icon info"/>
      <div className="text">
        {Translate.get(props.text)}
      </div>
    </div>
  )
}

export default InfoBox