import React, { Component } from 'react'
import { DatePicker, Input, Form, Col, Space, Button, Divider, Typography } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'
import Translate from '../../costra/Translate.js'

import EditPageHeader from '../../utils/EditPageHeader.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormInput from '../../utils/FormInput.js'
import { notificationError, notificationSuccess } from '../../utils/Notification.js'
import AuthorizationMembership from '../../entity/AuthorizationMembership.js'

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
  },
}

class DateSetEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      error: null,
      activeTab: 'set',
    }

    this.data = new EditData(this, 'item.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)

    this.changeValue = this.changeValue.bind(this)
    this.addInterval = this.addInterval.bind(this)
    this.removeInterval = this.removeInterval.bind(this)
  }

  load() {
    this.setState({ item: null })
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.date.FetchDateSet',
      id: (this.props.id === 'new' ? undefined : this.props.id),
    }).then((result) => {
      this.setState({
        item: result
      })
    }, () => {
      notificationError('error.load.date.set')
      Router.go('/datesets')
    })
  }

  apply() {
    var item = this.state.item
    item.fixedIntervals = (item.fixedIntervals || []).filter(interval => interval.from !== null && interval.to !== null)
    item.floatIntervals = (item.floatIntervals || []).filter(interval => interval.from !== null && interval.to !== null)
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.date.UpdateDateSet',
      item: item,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({ item: result })
      if (this.props.id !== result._id.$oid) {
        Router.go('/datesets/' + result._id.$oid)
      }
    }, () => {
      notificationError('error.store.named.date.time.set')
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.id !== this.props.id) {
      this.load()
    }
  }

  renderDateSet(disableEditItems) {

    return <>
      {this.renderIntervals(this.state.item.fixedIntervals, true, disableEditItems)}
      {this.renderIntervals(this.state.item.floatIntervals, false, disableEditItems)}
    </>
  }

  renderIntervals(intervals, fixed, disableEditItems) {
    var format
    if (fixed) {
      format = 'DD-MM'
    }
    else {
      format = 'DD-MM-YYYY'
    }

    const name = fixed ? Translate.get('date.set.fixedIntervals') : Translate.get('date.set.floatIntervals')

    return <Col>
      <Divider orientation="left">{name}</Divider>

      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 12 }}
      >
        {intervals && intervals.map((interval, index) =>

          <Form.Item
            key={fixed + 'interval' + index}
            label={Translate.get('date.set.interval.displayName')}
          >
            <Space align="center" wrap>
              <Input
                allowClear={false}
                disabled={disableEditItems}
                value={interval.displayName}
                onChange={(e) => this.changeValue(fixed, index, e.target.value, 'displayName')}
              />
              <Space align="center">
                <Typography.Text>{Translate.get('date.set.interval')}:</Typography.Text>
                <DatePicker.RangePicker
                  dropdownClassName="ant-custom-range-picker"
                  disabled={disableEditItems}
                  allowClear={false}
                  format={format}
                  value={[
                    interval.from && moment({ y: interval.from.year, M: (interval.from.monthOfYear - 1), d: interval.from.dayOfMonth }),
                    interval.to && moment({ y: interval.to.year, M: (interval.to.monthOfYear - 1), d: interval.to.dayOfMonth }),
                  ]}
                  onChange={(value) => this.changeValue(fixed, index, value, 'date')}
                />
              </Space>
              {!disableEditItems &&
                <Button
                  icon={<DeleteOutlined />}
                  title={Translate.get('delete.button')}
                  onClick={() => this.removeInterval(fixed, index)}
                />
              }
            </Space>
          </Form.Item>
        )}
        {!disableEditItems &&
          <Form.Item
            wrapperCol={{ offset: 4 }}
          >
            <Button
              type="dashed"
              onClick={() => this.addInterval(fixed)}
              icon={<PlusOutlined />}
            >
              {Translate.get('date.set.interval.add')}
            </Button>
          </Form.Item>
        }

      </Form>
    </Col>
  }

  removeInterval(fixed, index) {
    this.setState(oldState => {
      if (fixed) {
        oldState.item.fixedIntervals.splice(index, 1)
      }
      else {
        oldState.item.floatIntervals.splice(index, 1)
      }
      return {
        item: oldState.item
      }
    })
  }

  addInterval(fixed) {
    this.setState(oldState => {

      var interval = {
        displayName: '',
        from: null,
        to: null,
        _class: 'com.optimsys.costra.optimcall.shiftplanner.date.DateInterval',
      }

      if (fixed) {
        if (!oldState.item.fixedIntervals) {
          oldState.item.fixedIntervals = []
        }
        oldState.item.fixedIntervals.push(interval)
      }
      else {
        if (!oldState.item.floatIntervals) {
          oldState.item.floatIntervals = []
        }
        oldState.item.floatIntervals.push(interval)
      }

      return {
        item: oldState.item
      }
    })
  }

  changeValue(fixed, index, value, fieldName) {
    this.setState(oldState => {
      if (fieldName === 'date') {
        var from
        var to

        if (value) {
          if (value[0]) {
            from = {
              dayOfMonth: value[0].date(),
              dayOfYear: value[0].dayOfYear(),
              monthOfYear: value[0].month() + 1,
              year: value[0].year(),
              _class: 'com.optimsys.costra.optimcall.shiftplanner.date.DateStamp',
            }
          }
          if (value[1]) {
            to = {
              dayOfMonth: value[1].date(),
              dayOfYear: value[1].dayOfYear(),
              monthOfYear: value[1].month() + 1,
              year: value[1].year(),
              _class: 'com.optimsys.costra.optimcall.shiftplanner.date.DateStamp',
            }
          }
        }

        if (fixed) {
          oldState.item.fixedIntervals[index].from = from
          oldState.item.fixedIntervals[index].to = to
        }
        else {
          oldState.item.floatIntervals[index].from = from
          oldState.item.floatIntervals[index].to = to
        }
      }
      else {
        if (fixed) {
          oldState.item.fixedIntervals[index][fieldName] = value
        }
        else {
          oldState.item.floatIntervals[index][fieldName] = value
        }
      }
      return {
        item: oldState.item
      }
    })
  }

  render() {

    if (!this.state.item) {
      return <div className="content"><LoadingPlaceholder /></div>
    }
    const disableEditItems = !this.state.item._authorization.DateSetUpRem &&
      (this.props.id !== 'new' || !this.state.item._authorization.DateSetCreate)

    const dateSetSection = (
      <div className="form-section">
        <Form {...formItemLayout}>
          <FormInput.Text
            data={this.data}
            title="date.set.displayName"
            path="displayName"
            disabled={disableEditItems}
          />
        </Form>

        {this.renderDateSet(disableEditItems)}
      </div>
    )

    const authorizationSection = (
      <div className="form-section">
        <AuthorizationMembership data={this.data}
          path="authorization"
          tenant={this.state.item._tenant}
          disabled={disableEditItems}
          disableGroups={true} />
      </div>
    )

    return (
      <div className="content-column">
        <EditPageHeader
          component={this}
          disabled={disableEditItems}
          icon="time"
          title={this.state.item.displayName}
          titleUnnamed="entity.unnamed"
          titleCreate="date.set.create"
          closePath="/datesets/"
          tabs={[
            { key: 'set', title: 'info.main' },
            { key: 'authorization', title: 'entity.authorization' },
          ]}
        />

        {this.state.activeTab === 'set' && dateSetSection}
        {this.state.activeTab === 'authorization' && authorizationSection}

      </div>
    )
  }

}

export default DateSetEdit