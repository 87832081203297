import React from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import DialogFrame from './DialogFrame.js'
import LoadingPlaceholder from './LoadingPlaceholder.js'

import '../css/dialog.css'

function close() {
  Dialogs.close(null)
}

function DialogLoadingPlaceholder(props) {
  return <DialogFrame>
    <LoadingPlaceholder/>
    <div className="dialogControls">
      <div className="button right"
           onClick={close}>
        {Translate.get('close.button')}
      </div>
    </div>
  </DialogFrame>
}

export default DialogLoadingPlaceholder