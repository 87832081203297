import React from 'react'
import { Form, Input, Checkbox } from 'antd'

import Translate from '../../costra/Translate.js'

function Port(props) {

  const displayNameLabel = Translate.get('device.displayName')
  const isEnabledLabel = Translate.get('device.isEnabled')
  const phoneNumberLabel = Translate.get('device.port.phoneNumber')
  const portNoLabel = Translate.get('device.port.portNo')

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 8,
    },
  }

  switch (props.item._class) {

    case 'com.optimsys.costra.optimcall.device.SmsNeogatePort':

      const spanLabel = Translate.get('device.port.span')
      return <div className="formFrame">
        <div className="formRow">
          <label>{displayNameLabel + ':'}</label>
          <input title={displayNameLabel}
            placeholder={displayNameLabel + '...'}
            value={props.item.displayName}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'displayName', event.target.value, props.component)} />
        </div>
        <div className="formRow">
          <label>{isEnabledLabel + ':'}</label>
          <input type="checkbox"
            title={isEnabledLabel}
            placeholder={isEnabledLabel + '...'}
            checked={props.item.isEnabled}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'isEnabled', event.target.checked, props.component)} />
        </div>
        <div className="formRow">
          <label>{phoneNumberLabel + ':'}</label>
          <input title={phoneNumberLabel}
            placeholder={phoneNumberLabel + '...'}
            value={props.item.phoneNumber}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'phoneNumber', event.target.value, props.component)} />
        </div>
        <div className="formRow">
          <label>{portNoLabel + ':'}</label>
          <input title={portNoLabel}
            placeholder={portNoLabel + '...'}
            value={props.item.portNo}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'portNo', event.target.value, props.component)} />
        </div>
        <div className="formRow">
          <label>{spanLabel + ':'}</label>
          <input title={spanLabel}
            placeholder={spanLabel + '...'}
            value={props.item.span}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'span', event.target.value, props.component)} />
        </div>
        {!props.disabled && props.removePort}
      </div>


    case 'com.optimsys.costra.optimcall.device.SmsSmppPort':

      const systemIdLabel = Translate.get('device.port.systemId')
      const passwordLabel = Translate.get('device.port.password')
      return <div className="formFrame">
        <div className="formRow">
          <label>{displayNameLabel + ':'}</label>
          <input title={displayNameLabel}
            placeholder={displayNameLabel + '...'}
            value={props.item.displayName}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'displayName', event.target.value, props.component)} />
        </div>
        <div className="formRow">
          <label>{isEnabledLabel + ':'}</label>
          <input type="checkbox"
            title={isEnabledLabel}
            placeholder={isEnabledLabel + '...'}
            checked={props.item.isEnabled}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'isEnabled', event.target.checked, props.component)} />
        </div>
        <div className="formRow">
          <label>{portNoLabel + ':'}</label>
          <input title={portNoLabel}
            placeholder={portNoLabel + '...'}
            value={props.item.portNo}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'portNo', event.target.value, props.component)} />
        </div>
        <div className="formRow">
          <label>{phoneNumberLabel + ':'}</label>
          <input title={phoneNumberLabel}
            placeholder={phoneNumberLabel + '...'}
            value={props.item.phoneNumber}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'phoneNumber', event.target.value, props.component)} />
        </div>
        <div className="formRow">
          <label>{systemIdLabel + ':'}</label>
          <input title={systemIdLabel}
            placeholder={systemIdLabel + '...'}
            value={props.item.systemId}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'systemId', event.target.value, props.component)} />
        </div>
        <div className="formRow">
          <label>{passwordLabel + ':'}</label>
          <input type="password"
            title={passwordLabel}
            placeholder={passwordLabel + '...'}
            value={props.item.password}
            readOnly={props.disabled}
            onChange={(event) => props.changeValue(props.index, 'password', event.target.value, props.component)} />
        </div>
        {!props.disabled && props.removePort}
      </div>

    case 'com.optimsys.costra.optimcall.device.SmsSmsbranaPort':
    case 'com.optimsys.costra.optimcall.device.SmsOdorikPort':

      return <Form {...formItemLayout}>
        <Form.Item label={displayNameLabel}>
          {props.disabled 
            ? <span className="ant-form-text">{props.item.displayName}</span>
            :
            <Input 
              value={props.item.displayName}
              onChange={(event) => props.changeValue(props.index, 'displayName', event.target.value, props.component)}
            />
          }
        </Form.Item>

        <Form.Item label={isEnabledLabel}>
          {props.disabled 
            ? <span className="ant-form-text">{props.item.isEnabled ? Translate.get('yes.button') : Translate.get('no.button')}</span>
            :
            <Checkbox 
              checked={props.item.isEnabled}
              onChange={(event) => props.changeValue(props.index, 'isEnabled', event.target.checked, props.component)}
            />
          }
        </Form.Item>

        <Form.Item label={portNoLabel}>
          {props.disabled 
            ? <span className="ant-form-text">{props.item.portNo}</span>
            :
            <Input 
              value={props.item.portNo}
              onChange={(event) => props.changeValue(props.index, 'portNo', event.target.value, props.component)}
            />
          }
        </Form.Item>

        <Form.Item label={phoneNumberLabel}>
          {props.disabled 
            ? <span className="ant-form-text">{props.item.phoneNumber}</span>
            :
            <Input 
              value={props.item.phoneNumber}
              onChange={(event) => props.changeValue(props.index, 'phoneNumber', event.target.value, props.component)}
            />
          }
        </Form.Item>
        
        {!props.disabled && props.removePort}
      </Form>

    default:
      return <></>
  }
}

export default Port