import React, { Component } from 'react'
import { Button, Divider, Form } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'
import Translate from '../../costra/Translate.js'

import EditPageHeader from '../../utils/EditPageHeader.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormInput from '../../utils/FormInput.js'
import InfoBox from '../../utils/InfoBox.js'
import { notificationError, notificationSuccess } from '../../utils/Notification.js'

class TenantEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      activeTab: 'tenant',
    }
    this.data = new EditData(this, 'item.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)
    this.addDomain = this.addDomain.bind(this)
  }

  load() {
    this.setState({
      item: null,
    })
    WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.tenant.FetchTenant',
      id: (this.props.id === 'new' ? undefined : this.props.id),
    }).then((result) => {
      this.setState({
        item: result,
      })
    }, () => {
      notificationError('error.load.tenant')
      Router.go('/tenants')
    })
  }

  apply() {
    var item = this.state.item
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.tenant.UpdateTenant',
      item: item,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({
        item: result,
      })
      if (this.props.id !== result._id.$oid) {
        Router.go('/tenants/' + result._id.$oid)
      }
    }, () => {
      notificationError('error.store.tenant')
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.id !== this.props.id) {
      this.load()
    }
  }

  addDomain() {
    var domains = this.state.item.domains || []
    domains.push('')
    this.data.set('domains', domains)
  }

  render() {
    if (!this.state.item) {
      return <div className="content"><LoadingPlaceholder /></div>
    }

    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 8,
      },
    }

    const tenantInfoSection = (
      <div className="form-section">
        <Form {...formItemLayout}>
          <FormInput.Text
            data={this.data}
            title="tenant.name"
            path="name"
            disabled={!this.state.item._authorization.TenantCrud}
          />
        </Form>

        <Divider orientation="left">{Translate.get('tenant.domains')}</Divider>
        <InfoBox text={'tenant.domains.info'} />

        <Form
          wrapperCol={{
            span: 8,
            offset: 4,
          }}
        >
          {this.state.item.domains && this.state.item.domains.map((_, index) =>
            <FormInput.Text
              key={'domain' + index}
              data={this.data}
              path={'domains.' + index}
              disabled={!this.state.item._authorization.TenantCrud}
            >
              {this.state.item._authorization.TenantCrud &&
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => this.data.removeFromArray('domains', index)}
                >
                  {Translate.get('delete.button')}
                </Button>
              }
            </FormInput.Text>
          )}
          {this.state.item._authorization.TenantCrud &&
            <Form.Item
              wrapperCol={{
                span: 8,
                offset: 4,
              }}
            >
              <Button
                type="dashed"
                onClick={this.addDomain}
                icon={<PlusOutlined />}
              >
                {Translate.get('tenant.domain.add.button')}
              </Button>
            </Form.Item>
          }
        </Form>

      </div>
    )

    return (
      <div className="content-column">
        <EditPageHeader
          component={this}
          icon="tenant"
          title={this.state.item.name}
          titleUnnamed="entity.unnamed"
          titleCreate="tenant.create"
          closePath="/tenants/"
          tabs={[
            { key: 'tenant', title: 'info.main' },
          ]}
        />

        {this.state.activeTab === 'tenant' && tenantInfoSection}

      </div>
    )
  }

}

export default TenantEdit