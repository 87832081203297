import React from 'react'
import { PageHeader, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import Router from '../costra/Router.js'
import Translate from '../costra/Translate.js'
import InfoBox from './InfoBox.js'

function TableHeader(props) {
  return (
    <PageHeader
      avatar={{ icon: <div className={'icon big ' + props.icon} />, style: { backgroundColor: 'white' } }}
      style={{ backgroundColor: 'white', borderBottom: '1px solid lightgray' }}
      title={Translate.get(props.title)}
      extra={[
        props.allowAdd && (
          <Button
            key="create"
            type="primary"
            onClick={() => Router.go(props.addPath)}
            icon={<PlusOutlined />}
          >
            {Translate.get(props.addTitle)}
          </Button>
        )
      ]}
      footer={props.footer}
    >
      {props.noTenant && (
        <InfoBox text="tenant.notSelected.info" />
      )}
    </PageHeader>
  )
}

export default TableHeader