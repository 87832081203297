import React, { Component } from 'react'
import { Col, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import Router from '../../costra/Router.js'
import Dialogs from '../../costra/Dialogs.js'
import Translate from '../../costra/Translate.js'
import SimpleListContainer from '../../costra/SimpleListContainer.js'

import TableHeader from '../../utils/TableHeader.js'
import Table from '../../utils/Table.js'
import ErrorPlaceholder from '../../utils/ErrorPlaceholder.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import SearchContainer from '../../utils/SearchContainer.js'

import RemoveTenantDialog from './RemoveTenantDialog.js'

import '../../css/list.css'

const columns = [
  {
    title: () => Translate.get('table.name'),
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => <Button type="link" style={{ fontWeight: 'bold', padding: 0 }} onClick={() => Router.go('/tenants/' + record.key)}>{text}</Button>
  },
  {
    title: () => Translate.get('table.domains'),
    dataIndex: 'domains',
    key: 'domains',
    render: (_, record) => <Col>{record.domains.map((domain, index) => <div key={record.key + index}>{domain}</div>)}</Col>,
  },
  {
    title: '',
    key: 'action',
    fixed: 'right',
    width: 100,
    align: 'right',
    render: (_, record) => (
      <Button icon={<DeleteOutlined />} title={Translate.get('delete.button')} onClick={() => Dialogs.open(<RemoveTenantDialog item={record} />)} />
    ),
  }
]

class Tenants extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 50
    state.searchedText = ''
    this.state = state
    this.container = new SearchContainer(this, 'com.optimsys.costra.optimcall.shiftplanner.tenant.Tenants', () => {
      this.setState({ error: 'error.load.tenants' })
    })
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  render() {
    if (this.state.error) {
      return <div className="content"><ErrorPlaceholder error={this.state.error} /></div>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder />
    }

    const data = this.state.items.map(item => {
      return {
        key: item._id.$oid,
        name: item.name || Translate.get('entity.unnamed'),
        domains: item.domains || [],
      }
    })

    return <div className="content-column">
      <TableHeader
        icon="tenant"
        title="tenant.section"
        allowAdd={this.state.allowAdd}
        addPath="/tenants/new"
        addTitle="tenant.create.button"
      />

      <Table
        component={this}
        container={this.container}
        columns={columns}
        data={data}
      />
    </div>
  }
}

export default Tenants