import React, { Component } from 'react'
import Router from '../../costra/Router.js'
import WsContainers from '../../costra/WsContainers.js'
import Translate from '../../costra/Translate.js'

import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../../utils/ErrorPlaceholder.js'

class AuthorizedEntities extends Component {

  constructor(props) {
    super(props)
    this.state = {
      authorizedEntities: null,
      isOwner: null,
      error: null,
    }
    this.authorizedIdsCache = null
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.rights.FetchAuthorizedEntities',
      _tenant: this.props.tenant,
      authorizedIds: this.authorizedIdsCache
    }).then((result) => {
      var items = result.items || []
      var ownershipFlag = items.find((item) => item.isOwner !== undefined)
      this.setState({
        authorizedEntities: items.filter((item) => item.isOwner === undefined),
        isOwner: ownershipFlag.isOwner
      })
    }, () => {
      this.setState({error: 'error.load.entity.authorized'})
    })
  }

  componentDidMount() {
    this.authorizedIdsCache = this.props.data.get(this.props.path) || []
    this.load()
  }

  componentDidUpdate(prevProps) {
    var authorization = this.props.data.get(this.props.path) || []
    if (JSON.stringify(this.authorizedIdsCache) !== JSON.stringify(authorization)) {
      this.authorizedIdsCache = authorization
      this.load()
    }
  }

  removeAuthorizedEntity(entityId) {
    this.props.data.toggleIdInArray(this.props.path, entityId, false)
  }

  renderItem(item) {
    var isEnabled = !this.props.disabled && (item.isAuthorized || this.state.isOwner)
    if (!this.authorizedIdsCache.some((id) => id.$oid === item._id.$oid)) {
      return null
    }

    switch(item._class) {

      case 'com.optimsys.costra.group.Group':
        return <li key={item._id.$oid}>
          <div className={'button ' + (isEnabled ? '' : 'disabled')}
               onClick={() => isEnabled && this.removeAuthorizedEntity(item._id)}>
            <div className="icon group"/>
            <span>
              {item.name}
            </span>
          </div>
          {item.isGroupAccessible &&
            <div className="button"
                 title={Translate.get('group.edit')}
                 onClick={(event) => Router.go('/groups/' + item._id.$oid)}>
              <div className="icon edit"/>
            </div>
          }
        </li>

      case 'com.optimsys.costra.iddb.identity.Identity':
        return <li key={item._id.$oid}>
          <div className={'button ' + (isEnabled ? '' : 'disabled')}
               onClick={() => isEnabled && this.removeAuthorizedEntity(item._id)}>
            <div className="icon user"/>
            <span>
              {(item.extensions.adv.displayName ? item.extensions.adv.displayName : item.login)
                + ' ' + Translate.get('entity.authorization.asOwner')}
            </span>
          </div>
          {item.isAuthorized &&
            <div className="button"
                 title={Translate.get('user.edit')}
                 onClick={(event) => Router.go('/users/' + item._id.$oid)}>
              <div className="icon edit editButton"/>
            </div>
          }
        </li>

      default:
        return <li key={item._id.$oid}>
          <div className={'button ' + (isEnabled ? '' : 'disabled')}
               onClick={() => isEnabled && this.removeAuthorizedEntity(item._id)}>
            <div className="icon role"/>
            <span>
              {(item.user.extensions.adv.displayName ? item.user.extensions.adv.displayName : item.user.login)
                  + ' ' + Translate.get('entity.authorization.asRole') + ' ' + item.role.name}
            </span>
          </div>
          {item.isRoleAccessible &&
            <div className="button"
                 title={Translate.get('role.edit')}
                 onClick={(event) => Router.go('/roles/' + item.role._id.$oid)}>
              <div className="icon edit"/>
            </div>
          }
          {item.isAuthorized &&
            <div className="button"
                 title={Translate.get('user.edit')}
                 onClick={(event) => Router.go('/users/' + item.user._id.$oid)}>
              <div className="icon user"/>
            </div>
          }
        </li>
    }
  }

  render() {
    if (this.state.error) {
      return <div className="doubleList"><ErrorPlaceholder error={this.state.error}/></div>
    }
    if (!this.state.authorizedEntities) {
      return <div className="doubleList"><LoadingPlaceholder/></div>
    }

    return <div className="doubleList">
      <div className="header">
        <div className="icon right"/>
        <span>{Translate.get('entity.authorization.authorized')}</span>
      </div>
      <ul>
        {this.state.authorizedEntities.map((item) => this.renderItem(item))}
      </ul>
    </div>
  }

}

export default AuthorizedEntities