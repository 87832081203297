import React from 'react'
import { Button } from 'antd'
import { EditOutlined, SaveOutlined } from '@ant-design/icons'

import Translate from '../costra/Translate.js'

import '../css/form.css'

function toggleSection(props) {
  var sections = props.component.state.expandedSections
  if (!sections) {
    sections = {}
  }
  sections[props.name] = !sections[props.name]
  props.component.setState({expandedSections: sections})
}

function editControls(props) {
  if (props.editable) {
    if (props.component.state.editableSection === props.name) {
      return <div className="editControls">
        <Button type="primary"
                icon={<SaveOutlined />}
                onClick={(event) => {
                          event.stopPropagation()
                          props.component.saveSection()
                        }}>
          {Translate.get('save.button')}
        </Button>
        <Button onClick={(event) => {
                          event.stopPropagation()
                          props.component.cancelEditSection()
                        }}>
          {Translate.get('cancel.button')}
        </Button>
      </div>
    }
    else {
      return <div className="editControls">
        <Button icon={<EditOutlined />}
                onClick={(event) => {
                          event.stopPropagation()
                          if (!props.component.state.expandedSections[props.name]) {
                            toggleSection(props)
                          }
                          props.component.editSection(props.name)
                        }}>
          {Translate.get('edit.button')}
        </Button>
      </div>
    }
  }
  else {
    return null
  }
}

function FormSection(props) {
  var sections = props.component.state.expandedSections
  return (
    <div className="formSection">
      <h1 className={((sections && sections[props.name]) ? '' : 'disabled')}
          onClick={(event) => toggleSection(props)}>
        {props.icon && <div className={'icon ' + props.icon}/>}
        <span>{Translate.get(props.title)}</span>
        {editControls(props)}
      </h1>
      {(sections && sections[props.name]) && props.children}
    </div>
  )
}

export default FormSection