import React, { Component } from 'react'
import { Form } from 'antd'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'
import Translate from '../../costra/Translate.js'

import { notificationError, notificationSuccess } from '../../utils/Notification.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormInput from '../../utils/FormInput.js'
import EditPageHeader from '../../utils/EditPageHeader.js'

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: null,
      error: null,
      passwordsNotMatch : false,
    }

    this.data = new EditData(this, 'item.')
    this.dataExtension = new EditData(this, 'item.extensions.adv.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)
  }

  componentDidUpdate() {
    if (this.state.item) {
      if (this.state.passwordsNotMatch && (this.state.item.password === this.state.item.passwordCopy)) {
        this.setState({passwordsNotMatch : false})
      }
      else if (!this.state.passwordsNotMatch && (this.state.item.password !== this.state.item.passwordCopy)) {
        this.setState({passwordsNotMatch : true})
      }
    }
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.user.FetchProfile',
    }).then((result) => {
      result.passwordCopy = ""
      this.setState({
        item: result
      })
    }, () => {
      notificationError('failed.load.data')
      Router.go('/')
    })
  }

  apply() {
    var item = this.state.item
    if (item.password !== item.passwordCopy) {
      notificationError('user.passwords.not.match')
      return
    }
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.user.UpdateUser',
      item: item,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({
        item: result
      })
    }, () => {
      notificationError('failed.changes.apply')
    })
  }

  componentDidMount() {
    this.load()
  }

  render() {
    if (!this.state.item) {
      return <div className="content"><LoadingPlaceholder /></div>
    }

    const disableEditItems = !this.state.item._authorization.IdentityUpRem

    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 8,
      },
    }

    const userInfoSection = (
      <div className="form-section">

        <Form {...formItemLayout}>
          <FormInput.Text
            data={this.dataExtension}
            title="user.displayName"
            path="displayName"
            disabled={disableEditItems} />

          <FormInput.Password
            data={this.data}
            title="user.password"
            path="password"
            validateStatus={this.state.passwordsNotMatch ? 'error' : null}
            help={this.state.passwordsNotMatch ? Translate.get('user.passwords.not.match') : null}
            disabled={disableEditItems} />

          <FormInput.Password
            data={this.data}
            title="user.passwordCopy"
            path="passwordCopy"
            validateStatus={this.state.passwordsNotMatch ? 'error' : null}
            help={this.state.passwordsNotMatch ? Translate.get('user.passwords.not.match') : null}
            disabled={disableEditItems} />
        </Form>
      </div>
    )

    return (
      <div className="content-column">
        <EditPageHeader
          component={this}
          disabled={disableEditItems}
          icon="user"
          title={this.dataExtension.get('displayName') ? (this.dataExtension.get('displayName') + ' (' + this.state.item.login + ')')  : this.state.item.login}
          titleUnnamed="entity.unnamed"
          titleCreate="user.create"
          closePath="/"
          tabs={[
            { key: 'user', title: 'info.main' },
          ]}
        />

        {userInfoSection}
      </div>
    )
  }
}

export default Profile