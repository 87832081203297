import React from 'react'
import { Form, Input, Checkbox, TimePicker, InputNumber, Select, Space, Row, Col } from 'antd'
import moment from 'moment'
import momentTz from 'moment-timezone'

import Translate from '../costra/Translate.js'
import DurationInput from '../costra/DurationInput.js'

import '../css/form.css'

class FormInput {

  static Text(props) {
    const input = <Row gutter={[16, 0]}>
      {props.beforeInput &&
        <Col>
          {props.beforeInput}
        </Col>
      }
      <Col flex="auto">
        {props.disabled ? (
          props.data.get(props.path)
        ) : (
          <Input
            title={Translate.get(props.title)}
            placeholder={Translate.get(props.title) ? (Translate.get(props.title) + '...') : ''}
            value={props.data.get(props.path)}
            onChange={(event) => props.data.set(props.path, event.target.value)}
            addonBefore={props.addonBefore}
            addonAfter={props.addonAfter}
          />
        )}
      </Col>
    </Row>


    return <Form.Item label={Translate.get(props.title)}>
      {props.children
        ? (
          <Space wrap>
            {input}
            {props.children}
          </Space>
        )

        : input
      }
      {/* <Space wrap>
        {props.disabled
          ?
          <span className="ant-form-text">{props.data.get(props.path)}</span>
          :
          <Input
            title={Translate.get(props.title)}
            placeholder={Translate.get(props.title) + '...'}
            value={props.data.get(props.path)}
            onChange={(event) => props.data.set(props.path, event.target.value)}
          />
        }
        {props.children}
      </Space> */}
    </Form.Item>
  }

  static Password(props) {
    return <Form.Item
      label={Translate.get(props.title)}
      validateStatus={props.validateStatus}
      help={props.help}
    >
      <Input.Password
        autoComplete="new-password"
        title={Translate.get(props.title)}
        placeholder={Translate.get(props.title) + '...'}
        value={props.data.get(props.path)}
        disabled={props.disabled}
        visibilityToggle={false}
        onChange={(event) => props.data.set(props.path, event.target.value)}
      />
    </Form.Item>
  }

  static Check(props) {
    return <Form.Item label={Translate.get(props.title)}>
      {props.disabled
        ?
        <span className="ant-form-text">
          {props.data.get(props.path) ? Translate.get('yes.button') : Translate.get('no.button')}
        </span>
        :
        <Checkbox
          title={Translate.get(props.title)}
          placeholder={Translate.get(props.title) + '...'}
          checked={props.data.get(props.path)}
          onChange={(event) => props.data.set(props.path, event.target.checked)}
        />
      }
    </Form.Item>
  }

  static Select(props) {
    const val = props.data.get(props.path)
    return <Form.Item label={Translate.get(props.title)}>
      {props.disabled
        ? <span className="ant-form-text">{props.options.find(opt => opt.value === val)?.label}</span>
        :
        <Select
          value={val}
          options={props.options}
          onChange={(value) => props.data.set(props.path, value)}
          showSearch={props.showSearch}
        />
      }
    </Form.Item>
  }

  static SelectOpt(props) {
    return <Form.Item label={Translate.get(props.title)}>
      {props.disabled
        ?
        <span className="ant-form-text">
          {props.options.find(opt =>
            opt.value === (props.pathSuffix
              ? props.data.get(props.path + '.' + props.pathSuffix)
              : props.data.get(props.path))
          )?.label}
        </span>
        :
        <Select
          value={
            props.data.get(props.path)
              ? props.pathSuffix
                ? props.data.get(props.path + '.' + props.pathSuffix)
                : props.data.get(props.path)
              : props.undefinedValue
          }
          options={props.options}
          onChange={(value) =>
            (value === props.undefinedValue)
              ? props.data.set(props.path, undefined)
              : props.data.set(props.path, props.pathSuffix ? { [props.pathSuffix]: value } : value)
          }
        />
      }
    </Form.Item>
  }

  static SelectMulti(props) {
    return <Form.Item label={Translate.get(props.title)}>
      <Select
        mode="multiple"
        showArrow={true}
        disabled={props.disabled}
        value={(props.data.get(props.path) || []).map(item => props.pathSuffix ? item[props.pathSuffix] : item)}
        options={props.options}
        onChange={(items) => {
          if (Array.isArray(items)) {
            props.data.set(props.path, items.map(item => {
              if (props.pathSuffix) {
                return { [props.pathSuffix]: item }
              }
              else {
                return item
              }
            }))
          }
          else {
            props.data.set(props.path, [props.pathSuffix ? { [props.pathSuffix]: items } : items])
          }
        }}
      />
    </Form.Item>
  }

  static Textarea(props) {
    return <div className="formRow">
      {!props.noLabel && <label>{Translate.get(props.title)}:</label>}
      <textarea title={Translate.get(props.title)}
        placeholder={Translate.get(props.title) + '...'}
        value={props.data.get(props.path)}
        cols={props.cols}
        rows={props.rows}
        readOnly={props.disabled}
        onChange={(event) => props.data.set(props.path, event.target.value)} />
    </div>
  }

  static Duration(props) {
    return <div className="formRow">
      {!props.noLabel && <label>{Translate.get(props.title)}:</label>}
      <DurationInput value={props.data.get(props.path)}
        millis={true}
        disabled={props.disabled}
        onChange={(newValue) => props.data.set(props.path, newValue)} />
    </div>
  }

  static TimePicker(props) {
    return (
      <Form.Item className="wrap-label" label={Translate.get(props.title)}>
        <Space wrap align="baseline">
          <TimePicker
            allowClear={false}
            disabled={props.disabled}
            format="HH:mm"
            minuteStep={props.minuteStep}
            value={props.timezone ? momentTz.unix(props.data.get(props.path) / 1000).tz(props.timezone) : momentTz.unix(props.data.get(props.path) / 1000)}
            onChange={(value) => props.data.set(props.path, value.utc()._d.getTime() + '')}
          />
          {props.children}
        </Space>
      </Form.Item>
    )
  }

  // represent double field by time picker
  static TimePickerDouble(props) {
    return <Form.Item className="wrap-label" label={Translate.get(props.title)}>
      <TimePicker
        allowClear={false}
        disabled={props.disabled}
        format="HH:mm"
        minuteStep={props.minuteStep}
        value={moment({
          hours: Math.trunc(props.data.get(props.path)),
          minutes: (props.data.get(props.path) - Math.trunc(props.data.get(props.path))) * 60,
        })}
        onChange={(value) => props.data.set(props.path, (value.hours() + (value.minutes() / 60)))}
      />
    </Form.Item>
  }

  static InputNumber(props) {
    return <Form.Item label={Translate.get(props.title)}>
      {props.disabled ? (
        props.data.get(props.path)
      ) : (
        <InputNumber
          value={props.data.get(props.path)}
          min={props.min}
          step={props.step}
          onChange={(value) => props.data.set(props.path, value)}
        />
      )}
    </Form.Item>
  }

}

export default FormInput