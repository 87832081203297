import React from 'react'
import Translate from '../costra/Translate.js'

class SimpleInput {

  static Text(props) {
    return <input type="text"
                  className={props.className}
                  title={Translate.get(props.title)}
                  placeholder={Translate.get(props.title) + '...'}
                  value={props.data.get(props.path)}
                  disabled={props.disabled ? "disabled" : ""}
                  onChange={(event) => props.data.set(props.path, event.target.value)}/>
  }
}

export default SimpleInput