import React from 'react'
import Translate from '../costra/Translate.js'

function LoadingPlaceholder(props) {
  return (
    <div className = "loadingPlaceholder">
      <div className="icon loading"/>
      <span>{Translate.get('loading.splash')}</span>
    </div>
  )
}

export default LoadingPlaceholder