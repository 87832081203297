import React from 'react'

import '../css/dialog.css'

function DialogFrame(props) {
  return <div className="dialogOverlay">
    <div className={'dialogFrame' + (props.width ? (' ' + props.width) : '')}>
      {props.children}
    </div>
  </div>
}

export default DialogFrame