import React from 'react'
import Router from '../costra/Router.js'
import Translate from '../costra/Translate.js'

import '../css/controls.css'

function EditControls(props) {
  if (props.disabled) {
    return <div className="controls">
      {props.configConflict &&
        <div className="button left disabled error"
             title={Translate.get('config.conflict')}>
          <div className="icon error"
               title={Translate.get('config.conflict')}/>
        </div>
      }
      <div className="title">
        {props.icon && <div className={'icon ' + props.icon}/>}
        <span>{props.title}</span>
      </div>
      {props.returnPath &&
        <div className="button left"
             title={Translate.get('close.button')}
             onClick={(event) => Router.go(props.returnPath)}>
          <div className="icon close"/>
        </div>
      }
    </div>
  }

  return <div className="controls">
    {props.configConflict &&
      <div className="button left disabled error"
           title={Translate.get('config.conflict')}>
        <div className="icon error"/>
      </div>
    }
    <div className="title">
      {props.icon && <div className={'icon ' + props.icon}/>}
      <span>{props.title}</span>
    </div>
    <div className="button left"
         title={Translate.get('revert.button')}
         onClick={(event) => props.component.load()}>
      <div className="icon revert"/>
    </div>
    <div className="button left"
         title={Translate.get('apply.button')}
         onClick={(event) => props.component.apply()}>
      <div className="icon apply"/>
    </div>
    {props.returnPath &&
      <div className="button left"
           title={Translate.get('applyAndClose.button')}
           onClick={(event) => props.component.apply().then(() => Router.go(props.returnPath))}>
        <div className="icon applyAndClose"/>
      </div>
    }
    {props.returnPath &&
      <div className="button left"
           title={Translate.get('close.button')}
           onClick={(event) => Router.go(props.returnPath)}>
        <div className="icon close"/>
      </div>
    }
  </div>
}

export default EditControls