import SimpleListContainer from '../costra/SimpleListContainer.js'

class SearchContainer extends SimpleListContainer {

  static initialState() {
    return {
      items : null,
      page : 1,
      pages : 1,
      recordsTotal : 0,
      itemsPerPage : null,
      searchedText : '',
      sortField : null,
      sortOrder : null,
    }
  }

  search() {
    var params = this.params()
    this.tell({
      _class : 'com.optimsys.costra.ws.mongo.SimpleListContainer$Get',
      params : params,
    })
  }

  params() {
    var params = super.params()
    params['searchedText'] = this.component.state.searchedText
    params['sortField'] = this.component.state.sortField
    params['sortOrder'] = this.component.state.sortOrder
    params['page'] = this.component.state.page
    params['itemsPerPage'] = this.component.state.itemsPerPage
    params['ordering'] = this.component.state.ordering
    return params
  }
}

export default SearchContainer