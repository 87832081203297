import React from 'react'

import AuthorizedGlobalRoles from './roles/AuthorizedGlobalRoles.js'
import UnauthorizedGlobalRoles from './roles/UnauthorizedGlobalRoles.js'

function GlobalRoles(props) {
  return (
    <div className="doubleListContainer">
      <AuthorizedGlobalRoles data={props.data}
                             path={props.path}
                             disabled={props.disabled}
                             tenant={props.tenant}/>
      {!props.disabled &&
        <UnauthorizedGlobalRoles data={props.data}
                                 path={props.path}
                                 tenant={props.tenant}/>
      }
    </div>
  )
}

export default GlobalRoles