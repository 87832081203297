import React, { Component } from 'react'
import { Divider, Form } from 'antd'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'

import { notificationError, notificationSuccess } from '../../utils/Notification.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormInput from '../../utils/FormInput.js'
import EditPageHeader from '../../utils/EditPageHeader.js'

import AuthorizationMembership from '../../entity/AuthorizationMembership.js'
import GlobalRoles from './GlobalRoles.js'
import Translate from '../../costra/Translate.js'

class UserEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      error: null,
      passwordsNotMatch: false,
      activeTab: 'user',
    }
    this.data = new EditData(this, 'item.')
    this.dataExtension = new EditData(this, 'item.extensions.adv.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)
  }

  load() {
    this.setState({ item: null })
    WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.tenant.GetAvailableTenants',
    }).then((result) => {
      var values = []
      if (result.tenants) {
        result.tenants.forEach(tenant => values.push({
          value: tenant._id.$oid,
          label: tenant.name,
        }))
      }
      this.setState({
        tenants: values,
        tenantSelectionAvailable: result.tenantSelectionAvailable,
        allowedModifyIsUnbound: result.allowedModifyIsUnbound,
      })
    }, () => {
      notificationError('error.load.tenant')
      Router.go('/setup/users')
    })

    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.user.FetchUser',
      id: (this.props.id === 'new' ? undefined : this.props.id),
    }).then((result) => {
      result.passwordCopy = ""
      this.setState({
        item: result
      })
    }, () => {
      notificationError('failed.load.data')
      Router.go('/users')
    })
  }

  apply() {
    var item = this.state.item
    if (item.password !== item.passwordCopy) {
      notificationError('user.passwords.not.match')
      this.setState({ activeTab: 'user' })
      return
    }
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.user.UpdateUser',
      item: item,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({
        item: result
      })
      if (this.props.id !== result._id.$oid) {
        Router.go('/users/' + result._id.$oid)
      }
    }, () => {
      notificationError('failed.changes.apply')
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.id !== this.props.id) {
      this.load()
    }
    if (this.state.item) {
      if (this.state.passwordsNotMatch && (this.state.item.password === this.state.item.passwordCopy)) {
        this.setState({ passwordsNotMatch: false })
      }
      else if (!this.state.passwordsNotMatch && (this.state.item.password !== this.state.item.passwordCopy)) {
        this.setState({ passwordsNotMatch: true })
      }
    }
  }

  render() {
    if (!this.state.item) {
      return <div className="content"><LoadingPlaceholder /></div>
    }

    const disableEditItems = !this.state.item._authorization.IdentityUpRem &&
      (this.props.id !== 'new' || !this.state.item._authorization.IdentityCreate)

    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 8,
      },
    }

    const userInfoSection = (
      <div className="form-section">

        <Form {...formItemLayout}>

          {this.state.tenantSelectionAvailable &&
            <FormInput.Select
              data={this.data}
              title="user.tenant"
              path="_tenant.$oid"
              disabled={disableEditItems}
              options={this.state.tenants} />
          }

          <FormInput.Text
            data={this.data}
            title="user.login"
            path="login"
            disabled={disableEditItems} />

          {this.state.allowedModifyIsUnbound &&
            <FormInput.Check
              data={this.data}
              title="user.isUnbound"
              path="isUnbound"
              disableEditItems={disableEditItems} />
          }

          <FormInput.Text
            data={this.dataExtension}
            title="user.displayName"
            path="displayName"
            disabled={disableEditItems} />

          <FormInput.Password
            data={this.data}
            title="user.password"
            path="password"
            validateStatus={this.state.passwordsNotMatch ? 'error' : null}
            help={this.state.passwordsNotMatch ? Translate.get('user.passwords.not.match') : null}
            disabled={disableEditItems} />

          <FormInput.Password
            data={this.data}
            title="user.passwordCopy"
            path="passwordCopy"
            validateStatus={this.state.passwordsNotMatch ? 'error' : null}
            help={this.state.passwordsNotMatch ? Translate.get('user.passwords.not.match') : null}
            disabled={disableEditItems} />
        </Form>
      </div>
    )

    const authSection = (
      <div className="form-section">
        <Divider>{Translate.get('user.roles.global')}</Divider>

        <GlobalRoles data={this.dataExtension}
          path="globalRoles"
          tenant={this.state.item._tenant}
          disabled={!this.state.item._authorization.IdentityAssignGlobalRoles &&
            (this.props.id !== 'new' ||
              !this.state.item._authorization.IdentityCreate)} />

        <Divider>{Translate.get('entity.authorization')}</Divider>

        <AuthorizationMembership
          data={this.dataExtension}
          path="authorization"
          tenant={this.state.item._tenant}
          disabled={disableEditItems}
          disableGroups={true} />
      </div>
    )

    return (
      <div className="content-column">
        <EditPageHeader
          component={this}
          disabled={disableEditItems}
          icon="user"
          title={this.state.item.login}
          titleUnnamed="entity.unnamed"
          titleCreate="user.create"
          closePath="/users/"
          tabs={[
            { key: 'user', title: 'info.main' },
            { key: 'auth', title: 'entity.authorization' },
          ]}
        />

        {this.state.activeTab === 'user' && userInfoSection}
        {this.state.activeTab === 'auth' && authSection}

      </div>
    )
  }

}

export default UserEdit