import React, { Component } from 'react'
import { Button, ConfigProvider, Modal, Space } from 'antd'
import enGB from 'antd/lib/locale/en_GB'
import csCZ from 'antd/lib/locale/cs_CZ'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/cs'

import Container from './costra/Container.js'
import Router from './costra/Router.js'
import Dialogs from './costra/Dialogs.js'
import Translate from './costra/Translate.js'

import Login from './Login.js'
import Header from './Header.js'
import ShiftPlanner from './shiftplanner/ShiftPlanner.js'
import UserEdit from './setup/user/UserEdit.js'
import Users from './setup/user/Users.js'
import RoleEdit from './setup/role/RoleEdit.js'
import Roles from './setup/role/Roles.js'
import CalendarEdit from './setup/calendar/CalendarEdit.js'
import Calendars from './setup/calendar/Calendars.js'
import WorkerEdit from './setup/worker/WorkerEdit.js'
import Workers from './setup/worker/Workers.js'
import DateSetEdit from './setup/dateset/DateSetEdit.js'
import DateSets from './setup/dateset/DateSets.js'
import ApiKeyEdit from './setup/api/ApiKeyEdit.js'
import ApiKeys from './setup/api/ApiKeys.js'
import SmsConfigEdit from './setup/smsconf/SmsConfigEdit.js'
import SmsConfigs from './setup/smsconf/SmsConfigs.js'
import DeviceEdit from './setup/device/DeviceEdit.js'
import Devices from './setup/device/Devices.js'
import Stats from './stats/Stats.js'
import TenantEdit from './setup/tenant/TenantEdit.js'
import Tenants from './setup/tenant/Tenants.js'
import Shifts from './setup/shift/Shifts.js'
import Profile from './setup/user/Profile.js'

class AppWithIdentity extends Component {

  constructor(props) {
    super(props)
    this.state = {
      identity: {},
      isMobile: window.innerWidth < 480,
      isMenuCollapsed: false,
      isTenantSelectionVisible: false,
    }
    this.triggerMenuCollapsed = this.triggerMenuCollapsed.bind(this)

    this.container = new Container(this, 'com.optimsys.costra.optimcall.shiftplanner.user.CurrentUserInfo')
    this.routeProc = this.routeProc.bind(this)
    this.login = this.login.bind(this)
    this.selectActiveTenant = this.selectActiveTenant.bind(this)
  }

  triggerMenuCollapsed() {
    this.setState(oldState => {
      return {
        isMenuCollapsed: !oldState.isMenuCollapsed
      }
    })
  }

  getLocale() {
    if (Translate.language === 'cs') {
      moment.locale('cs')
      return csCZ
    }
    else {
      moment.locale('en-gb')
      return enGB
    }
  }

  containerUpdate() {
    return this.container.tell({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.user.CurrentUserInfo$Get',
    })
  }

  componentDidMount() {
    this.container.tell({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.user.CurrentUserInfo$Get',
    })

    window.addEventListener('resize', this.throttledHandleWindowResize)
  }

  componentWillUnmount() {
    this.container.close()
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }

  login(login, password) {
    return this.container.ask({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.user.CurrentUserInfo$Login',
      login: login,
      password: password,
    })
  }

  throttledHandleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < 480 })
  }

  selectActiveTenant() {
    this.setState({ isTenantSelectionVisible: true })
  }

  routeContentProc(path) {
    switch (path[0]) {
      case '':
        return <ShiftPlanner id={this.state.identity.id.$oid} isMobile={this.state.isMobile} />

      case 'users':
        if (path[1]) return <UserEdit id={path[1]} />
        else return <Users />

      case 'roles':
        if (path[1]) return <RoleEdit id={path[1]} />
        else return <Roles />

      case 'calendars':
        if (path[1]) return <CalendarEdit id={path[1]} />
        else return <Calendars />

      case 'workers':
        if (path[1]) return <WorkerEdit id={path[1]} />
        else return <Workers />

      case 'datesets':
        if (path[1]) return <DateSetEdit id={path[1]} />
        else return <DateSets />

      case 'stats':
        return <Stats isMobile={this.state.isMobile} />

      case 'keys':
        if (path[1]) return <ApiKeyEdit id={path[1]} />
        else return <ApiKeys />

      case 'smsconfigs':
        if (path[1]) return <SmsConfigEdit id={path[1]} />
        else return <SmsConfigs />

      case 'devices':
        if (path[1]) return <DeviceEdit id={path[1]} />
        else return <Devices />

      case 'tenants':
        if (path[1]) return <TenantEdit id={path[1]} />
        else return <Tenants />

      case 'shiftsOverview':
        return <Shifts />

      case 'profile':
        return <Profile/>

      default:
        return <div>Unknown page</div>
    }
  }

  routeProc(path) {
    return <div>
      <Header path={path}
        isMobile={this.state.isMobile}
        identity={this.state.identity}
        selectActiveTenant={this.selectActiveTenant}
        allowTenantChange={(this.state.availableTenants && this.state.availableTenants.length > 1) ||
          this.state.identity.canAccessOtherTenants} />

      <div className="page-content">
        {this.routeContentProc(path)}
      </div>
    </div>
  }

  render() {
    if (!this.state.identity.id) {
      return <Login loginProc={this.login} />
    }

    var tenants = []
    if (this.state.identity.canAccessOtherTenants) {
      tenants.unshift({ name: Translate.get('tenant.select.all') })
    }
    this.state.availableTenants && this.state.availableTenants.forEach(item => tenants.push(item))

    return <div>
      <ConfigProvider locale={this.getLocale()}>
        <Router proc={this.routeProc} />
        <Dialogs />
        <Modal
          title={Translate.get('tenant.select.prompt')}
          visible={this.state.isTenantSelectionVisible}
          onCancel={() => this.setState({ isTenantSelectionVisible: false })}
          footer={null}
        >
          <Space wrap>
            {tenants.map(tenant =>
              <Button
                type={tenant._id?.$oid === this.state.identity.tenant?.$oid ? 'primary' : 'dashed'}
                key={tenant.name}
                onClick={() => {
                  this.container.tell({
                    _class: 'com.optimsys.costra.optimcall.shiftplanner.user.CurrentUserInfo$SetActiveTenant',
                    id: tenant._id,
                  })
                  this.setState({ isTenantSelectionVisible: false })
                }}
              >
                {tenant.name}
              </Button>
            )}
          </Space>
        </Modal>
      </ConfigProvider>
    </div>
  }
}

export default AppWithIdentity
