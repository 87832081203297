import React, { Component } from 'react'
import moment from 'moment'
import { Button, Space } from 'antd'

import Router from '../../costra/Router.js'
import Translate from '../../costra/Translate.js'
import SimpleListContainer from '../../costra/SimpleListContainer.js'

import Table from '../../utils/Table.js'
import TableHeader from '../../utils/TableHeader.js'
import ErrorPlaceholder from '../../utils/ErrorPlaceholder.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import SearchContainer from '../../utils/SearchContainer.js'

import '../../css/list.css'

const columns = [
  {
    title: () => Translate.get('table.time'),
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: () => Translate.get('table.assigner'),
    dataIndex: 'assigner',
    key: 'assigner',
  },
  {
    title: () => Translate.get('table.assignees'),
    dataIndex: 'assignees',
    key: 'assignees',
    render: assignees => (
      <Space size="small" wrap>
        {assignees.sort((a, b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0)).map((assignee, index) =>
          <Button
            key={index}
            size="small"
            onClick={() => Router.go('/workers/' + assignee._id.$oid)}
          >
            {assignee.firstName + ' ' + assignee.familyName}
          </Button>
        )}
      </Space>
    )
  },
  {
    title: () => Translate.get('table.calendar'),
    dataIndex: 'calendar',
    key: 'calendar',
  },
  {
    title: () => Translate.get('table.startTime'),
    dataIndex: 'startTime',
    key: 'startTime',
  },
  {
    title: () => Translate.get('table.endTime'),
    dataIndex: 'endTime',
    key: 'endTime',
  },
]

class Shifts extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 50
    state.searchedText = ''
    this.state = state
    this.container = new SearchContainer(this, 'com.optimsys.costra.optimcall.shiftplanner.shift.Shifts', () => {
      this.setState({ error: 'error.load.shifts' })
    })

  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder error={this.state.error} />
    }
    if (!this.state.items) {
      return <LoadingPlaceholder />
    }

    const data = this.state.items.map(item => {
      return {
        key: item._id.$oid,
        assigner: item.assignerName || item.assignerLogin || Translate.get('entity.unnamed'),
        assignees: item.assigneeObjects || [],
        calendar: item.calendarName || Translate.get('entity.unnamed'),
        startTime: moment.unix(item.startTime / 1000).format('YYYY-MM-DD HH:mm'),
        endTime: moment.unix(item.endTime / 1000).format('YYYY-MM-DD HH:mm'),
        time: moment.unix(item.time / 1000).format('YYYY-MM-DD HH:mm'),
      }
    })

    return <div className="content-column">
      <TableHeader
        icon="calendar"
        title="shift.section"
        allowAdd={false}
      />

      <Table
        component={this}
        container={this.container}
        columns={columns}
        data={data}
      />
    </div>
  }
}

export default Shifts