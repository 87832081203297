import React, { Component } from 'react'
import Dialogs from '../../costra/Dialogs.js'
import Translate from '../../costra/Translate.js'
import WsContainers from '../../costra/WsContainers.js'

import DialogFrame from '../../utils/DialogFrame.js'
import DialogErrorPlaceholder from '../../utils/DialogErrorPlaceholder.js'
import DialogLoadingPlaceholder from '../../utils/DialogLoadingPlaceholder.js'

import '../../css/dialog.css'

class RemoveDeviceDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      entityUsages: null,
      working: false,
      error: null,
    }
    this.close = this.close.bind(this)
    this.removeItem = this.removeItem.bind(this)
  }

  componentDidMount() {
    this.load()
  }

  load() {
    this.setState({entityUsages : []})
  }

  close() {
    Dialogs.close(false)
  }

  removeItem() {
    this.setState({working: true})
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.device.RemoveDevice',
      id: this.props.item._id,
    }).then(() => {
      Dialogs.close(true)
    }, () => {
      this.setState({error: 'error.remove.device'})
    })
  }

  render() {
    if (this.state.error) {
      return <DialogErrorPlaceholder error={this.state.error}/>
    }
    if (!this.state.entityUsages || this.state.working) {
      return <DialogLoadingPlaceholder/>
    }
    return <DialogFrame>
      {this.state.entityUsages.length > 0
        ?
        <>
          <div>{Translate.get('entity.remove.error.used.prompt')}</div>
          <div className="dialogControls">
            <div className="button right"
                 title={Translate.get('close.button')}
                 onClick={this.close}>
              {Translate.get('close.button')}
            </div>
          </div>
        </>

        :
        <>
          <div>
            {Translate.get('device.remove.prompt').replace('%device%', this.props.item.name)}
          </div>
          <div className="dialogControls">
            <div className="button right"
                 title={Translate.get('no.button')}
                 onClick={this.close}>
              {Translate.get('no.button')}
            </div>
            <div className="button right"
                 title={Translate.get('yes.button')}
                 onClick={this.removeItem}>
              {Translate.get('yes.button')}
            </div>
          </div>
        </>
      }

    </DialogFrame>
  }
}

export default RemoveDeviceDialog