import { message } from 'antd'

import Translate from '../costra/Translate.js'

export const notificationSuccess = (msg) => (
  message.success({
    content: Translate.get(msg),
    style: {
      marginTop: '35px'
    },
    key: msg,
    onClick: () => message.destroy(msg)
  })
)

export const notificationError = (msg) => (
  message.error({
    content: Translate.get(msg),
    style: {
      marginTop: '35px'
    },
    key: msg,
    onClick: () => message.destroy(msg)
  })
)

export const notificationInfo = (msg) => (
  message.info({
    content: Translate.get(msg),
    style: {
      marginTop: '35px'
    },
    key: msg,
    onClick: () => message.destroy(msg)
  })
)