import React, { Component } from 'react'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import Router from '../../costra/Router.js'
import Dialogs from '../../costra/Dialogs.js'
import Translate from '../../costra/Translate.js'
import SimpleListContainer from '../../costra/SimpleListContainer.js'

import Table from '../../utils/Table.js'
import TableHeader from '../../utils/TableHeader.js'
import ErrorPlaceholder from '../../utils/ErrorPlaceholder.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import SearchContainer from '../../utils/SearchContainer.js'

import RemoveWorkerDialog from './RemoveWorkerDialog.js'

import '../../css/list.css'

class Workers extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 50
    state.searchedText = ''
    state.sortField = 'familyName'
    state.sortOrder = 1
    state.columns = this.getColumnConfig('familyName', 1)
    this.state = state
    this.container = new SearchContainer(this, 'com.optimsys.costra.optimcall.shiftplanner.worker.Workers', () => {
      this.setState({ error: 'error.load.workers' })
    })
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  getColumnConfig(sortField, sortOrder) {
    return (
      [
        {
          title: Translate.get('table.familyName'),
          dataIndex: 'familyName',
          key: 'familyName',
          width: '25%',
          render: (text, record) => <Button type="link" style={{ fontWeight: 'bold', padding: 0 }} onClick={() => Router.go('/workers/' + record.key)}>{text}</Button>,
          sorter: true,
          sortOrder: (sortField === 'familyName') ? (sortOrder > 0 ? 'ascend' : 'descend') : false,
          showSorterTooltip: false,
          onHeaderCell: (column) => {
            return {
              onClick: () => {
                this.setState(oldState => {
                  let newSortOrder = (oldState.sortField === column.dataIndex) ? -oldState.sortOrder : 1
                  return {
                    sortField: column.dataIndex,
                    sortOrder: newSortOrder,
                    columns: this.getColumnConfig(column.dataIndex, newSortOrder)
                  }
                }, () => this.container.reload())
              }
            }
          }
        },
        {
          title: Translate.get('table.firstName'),
          dataIndex: 'firstName',
          key: 'firstName',
          width: '25%',
          render: (text, record) => <Button type="link" style={{ fontWeight: 'bold', padding: 0 }} onClick={() => Router.go('/workers/' + record.key)}>{text}</Button>,
          sorter: true,
          sortOrder: (sortField === 'firstName') ? (sortOrder > 0 ? 'ascend' : 'descend') : false,
          showSorterTooltip: false,
          onHeaderCell: (column) => {
            return {
              onClick: () => {
                this.setState(oldState => {
                  let newSortOrder = (oldState.sortField === column.dataIndex) ? -oldState.sortOrder : 1
                  return {
                    sortField: column.dataIndex,
                    sortOrder: newSortOrder,
                    columns: this.getColumnConfig(column.dataIndex, newSortOrder)
                  }
                }, () => this.container.reload())
              }
            }
          }
        },
        {
          title: Translate.get('table.phone.distribution'),
          dataIndex: 'phoneDistribution',
          key: 'phoneDistribution',
          width: '25%',
        },
        {
          title: Translate.get('table.phone.sms'),
          dataIndex: 'phoneSms',
          key: 'phoneSms',
          width: '25%',
        },
        {
          title: '',
          key: 'action',
          fixed: 'right',
          width: 100,
          align: 'right',
          render: (_, record) => (
            <Button
              icon={<DeleteOutlined />}
              title={Translate.get('delete.button')}
              onClick={() => Dialogs.open(<RemoveWorkerDialog item={record} />)}
            />
          ),
        }
      ]
    )
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder error={this.state.error} />
    }
    if (!this.state.items) {
      return <LoadingPlaceholder />
    }
    const { columns } = this.state
    const data = this.state.items.map(item => {
      return {
        key: item._id.$oid,
        //name: item.displayName || Translate.get('entity.unnamed'),
        firstName: item.firstName || '',
        familyName: item.familyName || '',
        phoneSms: item.phoneNumberToReceiveSms,
        phoneDistribution: item.phoneNumberToDistribute,
      }
    })

    return <div className="content-column">
      <TableHeader
        icon="worker"
        title="worker.section"
        noTenant={this.state.noTenant}
        allowAdd={this.state.allowAdd}
        addPath="/workers/new"
        addTitle="worker.create.button"
      />

      <Table
        component={this}
        container={this.container}
        columns={columns}
        data={data}
      />
    </div>
  }
}

export default Workers