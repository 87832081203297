import React, { Component } from 'react'
import { Select, DatePicker, Checkbox } from 'antd'
import moment from 'moment';

import Translate from '../costra/Translate.js'
import SimpleListContainer from '../costra/SimpleListContainer.js'

import Table from '../utils/Table.js'
import TableHeader from '../utils/TableHeader.js'
import InfoBox from '../utils/InfoBox.js'

import "../css/stats.css";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: () => Translate.get('table.name'),
    dataIndex: 'name',
    key: 'name',
    width: '40%',
  },
  {
    title: () => Translate.get('table.hours'),
    dataIndex: 'hours',
    key: 'hours',
    width: '20%',
  },
  {
    title: () => Translate.get('table.workingDayShifts'),
    dataIndex: 'workingDayShifts',
    key: 'workingDayShifts',
    width: '20%',
  },
  {
    title: () => Translate.get('table.nonWorkingDayShifts'),
    dataIndex: 'nonWorkingDayShifts',
    key: 'nonWorkingDayShifts',
    width: '20%',
  }
]

class Container extends SimpleListContainer {
  params() {
    var params = super.params()
    params['calendar'] = this.component.state.selectedCalendar ? this.component.state.selectedCalendar._id : undefined
    params['startTime'] = this.component.state.startTime._d.getTime()
    params['endTime'] = this.component.state.endTime._d.getTime()
    return params
  }
}

class Stats extends Component {
  constructor(props) {
    super(props)

    var state = SimpleListContainer.initialState()
    state.stats = null
    state.calendars = []
    state.selectedCalendar = null
    state.startTime = moment().startOf('month')
    state.endTime = moment().endOf('month')
    state.showTotalZeroWorkers = false
    this.state = state

    this.ranges = {}
    this.ranges[Translate.get('date.range.today')] = [moment().startOf('day'), moment().endOf('day')]
    this.ranges[Translate.get('date.range.yesterday')] = [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')]
    this.ranges[Translate.get('date.range.last.week')] = [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')]
    this.ranges[Translate.get('date.range.this.week')] = [moment().startOf('week'), moment().endOf('week')]
    this.ranges[Translate.get('date.range.this.month')] = [moment().startOf('month'), moment().endOf('month')]
    this.ranges[Translate.get('date.range.last.month')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    this.ranges[Translate.get('date.range.last.threemonths')] = [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

    this.container = new Container(this, 'com.optimsys.costra.optimcall.shiftplanner.stat.Stats', () => {
      this.setState({ error: 'error.load.stats' })
    })

  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  applyDateRangeFromPicker(value) {
    if (value) {
      this.setState({
        startTime: value[0].startOf('day'),
        endTime: value[1].endOf('day')
      }, () => {
        this.container.reload()
      })
    }
  }

  calculateDisplayName = (worker, displayNamePattern) => {
    return displayNamePattern.replaceAll('%firstName%', worker.firstName || '').replaceAll('%familyName%', worker.familyName || '')
  }

  render() {
    if (this.state.calendars && this.state.calendars.length === 0) {
      return <div className="content">
        <InfoBox text="no.calendar.message" />
      </div>
    }

    const dateFormat = 'DD.MM.yyyy'

    const data = (this.state.stats || []).map(item => {
      item['workerName'] = this.calculateDisplayName(
        item.worker, this.state.selectedCalendar.workerDisplayNamePattern || '%firstName% %familyName%'
      )
      // removing diacritics for proper sorting
      item['workerNameSort'] = item.workerName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      return item
    }).sort((item1, item2) => {
      // sorting by normalized worker name string
      if (item1.workerNameSort === item2.workerNameSort) return 0
      return item1.workerNameSort < item2.workerNameSort ? -1 : 1
    }).flatMap((item) => {
      if (!this.state.showTotalZeroWorkers && item.totalWorkerStats.hours === 0) {
        // worker with total hours 0 should not be displayed in this case
        return []
      }
      if (this.state.selectedCalendar.numberOfWorkersPerShift < 2) {
        return [{
          key: item.worker._id.$oid + (item.totalWorkerStats.hours === 0 ? 'workerZero' : ''),
          name: item.workerName,
          hours: item.totalWorkerStats.hours,
          workingDayShifts: item.totalWorkerStats.workingDayShifts,
          nonWorkingDayShifts: item.totalWorkerStats.nonWorkingDayShifts,
        }]
      }
      return (Object.keys(item.workerPositions) || []).map(position => {
        return {
          key: item.worker._id.$oid + position + (item.totalWorkerStats.hours === 0 ? 'workerZero' : ''),
          name: Translate.get('statistics.workerInPosition', null, { worker: item.workerName, position: position }),
          hours: item.workerPositions[position].hours,
          workingDayShifts: item.workerPositions[position].workingDayShifts,
          nonWorkingDayShifts: item.workerPositions[position].nonWorkingDayShifts,
        }
      }).concat([{
        key: item.worker._id.$oid + 'workerTotal' + (item.totalWorkerStats.hours === 0 ? 'workerZero' : ''),
        name: Translate.get('statistics.workerTotal', null, { worker: item.workerName }),
        hours: item.totalWorkerStats.hours,
        workingDayShifts: item.totalWorkerStats.workingDayShifts,
        nonWorkingDayShifts: item.totalWorkerStats.nonWorkingDayShifts,
      }])
    })

    return <div className="content-column stats">
      <TableHeader
        icon="calendar"
        title="statistics.section"
        allowAdd={false}
        footer={
          <div className="left-mid-row">
            {this.state.calendars && this.state.calendars.length === 1 &&
              <div className="calendar-name">
                {this.state.calendars[0].displayName}
              </div>
            }
            {this.state.calendars && this.state.calendars.length > 1 && (
              <Select
                onChange={(value) => this.setState({ selectedCalendar: this.state.calendars.find(cal => cal._id.$oid === value) }, () => this.container.reload())}
                value={this.state.selectedCalendar._id.$oid}
              >
                {this.state.calendars && this.state.calendars.map(item => {
                  return <Select.Option key={item._id.$oid} value={item._id.$oid}>{item.displayName}</Select.Option>
                })}
              </Select>
            )}
            <RangePicker
              dropdownClassName="ant-custom-range-picker"
              value={[this.state.startTime, this.state.endTime]}
              ranges={this.ranges}
              format={dateFormat}
              onChange={value => this.applyDateRangeFromPicker(value)}
            />
            <Checkbox
              checked={this.state.showTotalZeroWorkers}
              onChange={(event) => this.setState({ showTotalZeroWorkers: event.target.checked })}
            >
              {Translate.get('statistics.showWorkersWithTotalZero')}
            </Checkbox>
          </div>
        }
      />

      <Table
        component={this}
        container={this.container}
        columns={columns}
        data={data}
        rowClassName={(record) => {
          var result = ''
          if (record && record.key) {
            if (record.key.includes('workerTotal')) result += ' workerTotal'
            if (record.key.includes('workerZero')) result += ' workerZero'
          }
          return result
        }}
        disabledPagination
      />
    </div>
  }
}

export default Stats