import React, { Component } from 'react'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import Router from '../../costra/Router.js'
import Dialogs from '../../costra/Dialogs.js'
import Translate from '../../costra/Translate.js'
import SimpleListContainer from '../../costra/SimpleListContainer.js'

import Table from '../../utils/Table.js'
import TableHeader from '../../utils/TableHeader.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import SearchContainer from '../../utils/SearchContainer.js'

import RemoveDateSetDialog from './RemoveDateSetDialog.js'

const columns = [
  {
    title: () => Translate.get('table.name'),
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => <Button type="link" style={{ fontWeight: 'bold', padding: 0 }} onClick={() => Router.go('/datesets/' + record.key)}>{text}</Button>
  },
  {
    title: '',
    key: 'action',
    fixed: 'right',
    width: 100,
    align: 'right',
    render: (_, record) => (
      <Button
        icon={<DeleteOutlined />}
        title={Translate.get('delete.button')}
        onClick={() => Dialogs.open(<RemoveDateSetDialog item={record} />)}
      />
    ),
  }
]

class NamedDateTimeSets extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 50
    state.searchedText = ''
    this.state = state
    this.container = new SearchContainer(this, 'com.optimsys.costra.optimcall.shiftplanner.date.DateSets', () => {
      this.setState({ error: "error.load.named.date.time.sets" })
    })
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  render() {
    if (!this.state.items) {
      return <LoadingPlaceholder />
    }

    const data = this.state.items.map(item => {
      return {
        key: item._id.$oid,
        name: item.displayName || Translate.get('entity.unnamed'),
      }
    })

    return <div className="content-column">
      <TableHeader
        icon="time"
        title="date.set.section"
        noTenant={this.state.noTenant}
        allowAdd={this.state.allowAdd}
        addPath="/datesets/new"
        addTitle="date.set.create.button"
      />

      <Table
        component={this}
        container={this.container}
        columns={columns}
        data={data}
      />
    </div>
  }

}

export default NamedDateTimeSets