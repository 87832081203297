import React, { Component } from 'react'
import Router from '../../../costra/Router.js'
//import WsContainers from '../../../costra/WsContainers.js'
import Translate from '../../../costra/Translate.js'

import LoadingPlaceholder from '../../../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../../../utils/ErrorPlaceholder.js'

class Groups extends Component {

  constructor(props) {
    super(props)
    this.state = {
      groups: null,
      error: null,
    }
  }

  load() {
    /*WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.group.FetchGroups',
      _tenant: this.props.tenant,
    }).then((result) => {
      this.setState({groups: (result.items || [])})
    }, () => {
      this.setState({error: 'error.load.groups'})
    })*/
    this.setState({groups : []})
  }

  componentDidMount() {
    this.load()
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder error={this.state.error}/>
    }
    if (!this.state.groups) {
      return <LoadingPlaceholder/>
    }
    var authorization = this.props.data.get(this.props.path) || []

    return <ul>
      {this.state.groups.map((item) =>
        !authorization.some((id) => id.$oid === item._id.$oid) &&
        item.name.toLowerCase().includes(this.props.searchText.toLowerCase()) &&
        <li key={item._id.$oid}>

          <div className="button"
               onClick={() => this.props.data.toggleIdInArray(this.props.path, item._id, true)}>
            <div className="icon group"/>
            <span>
              {item.name}
            </span>
          </div>
          {!this.props.disabled &&
            <div className="button"
                 title={Translate.get('group.edit')}
                 onClick={(event) => Router.go('/groups/' + item._id.$oid)}>
              <div className="icon edit editButton"/>
            </div>
          }
        </li>
      )}
    </ul>
  }

}

export default Groups