import React from 'react'
import { Menu, Dropdown, Row, Col } from 'antd'
import { LogoutOutlined, DeploymentUnitOutlined, UserOutlined } from '@ant-design/icons';

import Identity from './costra/Identity.js'
import Translate from './costra/Translate.js'
import Router from './costra/Router.js'

import './css/header.css'

function Header(props) {

  const userCtxMenu = (
    <Menu>
      {props.allowTenantChange && props.isMobile &&
        <Menu.Item
          icon={<DeploymentUnitOutlined />}
          key="userCtxMenuTenant"
          onClick={props.selectActiveTenant}>
          {props.identity.tenant ? props.identity.tenantName : Translate.get('tenant.notSelected.title')}
        </Menu.Item>
      }
      <Menu.Item
        icon={<UserOutlined/>}
        key="userCtxMenuProfile"
        onClick={() => Router.go('/profile')}
      >
        {Translate.get('user.profile')}
      </Menu.Item>
      <Menu.Item
        icon={<LogoutOutlined />}
        key="userCtxMenuLogOut"
        onClick={Identity.logout}
      >
        {Translate.get('user.logOut')}
      </Menu.Item>
    </Menu>
  )

  const languageCtxMenu = (
    <Menu>
      <Menu.Item
        key="languageEn"
        onClick={() => {
          document.cookie = "appLanguage=en"
          Translate.setLanguage('en')
        }}
      >
        <Row>
          <div className="icon small language-en" />
          {Translate.get('language.en')}
        </Row>
      </Menu.Item>

      <Menu.Item
        key="languageCs"
        onClick={() => {
          document.cookie = "appLanguage=cs"
          Translate.setLanguage('cs')
        }}
      >
        <Row>
          <div className="icon small language-cs" />
          {Translate.get('language.cs')}
        </Row>
      </Menu.Item>
    </Menu>
  )

  return <div className="page-header">
    <Row wrap={false} align="middle">
      <Col flex="auto">
        <Menu theme="dark" mode="horizontal" style={{ lineHeight: '33px' }} selectedKeys={[props.path[0] || 'shiftplanner']}>
          <Menu.Item key="shiftplanner" onClick={() => Router.go('/')}>
            {Translate.get('shiftplanner.section')}
          </Menu.Item>
          {props.identity.menu.statsSection &&
            <Menu.Item key="stats" onClick={() => Router.go('/stats/')}>
              {Translate.get('statistics.section')}
            </Menu.Item>
          }
          {props.identity.menu.usersSection &&
            <Menu.Item key="users" onClick={() => Router.go('/users/')}>
              {Translate.get('user.section')}
            </Menu.Item>
          }
          {props.identity.menu.calendarsSection &&
            <Menu.Item key="calendars" onClick={() => Router.go('/calendars/')}>
              {Translate.get('calendar.section')}
            </Menu.Item>
          }
          {props.identity.menu.workersSection &&
            <Menu.Item key="workers" onClick={() => Router.go('/workers/')}>
              {Translate.get('worker.section')}
            </Menu.Item>
          }
          {props.identity.menu.dateSetsSection &&
            <Menu.Item key="datesets" onClick={() => Router.go('/datesets/')}>
              {Translate.get('date.set.section')}
            </Menu.Item>
          }
          {props.identity.menu.suSection &&
            <Menu.SubMenu key="suMenu" title={Translate.get('settings.super.user')}>
              <Menu.Item key="tenants" onClick={() => Router.go('/tenants/')}>
                {Translate.get('tenant.section')}
              </Menu.Item>
              <Menu.Item key="roles" onClick={() => Router.go('/roles/')}>
                {Translate.get('role.section')}
              </Menu.Item>
              <Menu.Item key="devices" onClick={() => Router.go('/devices/')}>
                {Translate.get('device.section')}
              </Menu.Item>
              <Menu.Item key="smsconfigs" onClick={() => Router.go('/smsconfigs/')}>
                {Translate.get('sms.config.section')}
              </Menu.Item>
              <Menu.Item key="keys" onClick={() => Router.go('/keys/')}>
                {Translate.get('api.key.section')}
              </Menu.Item>
              <Menu.Item key="shiftsOverview" onClick={() => Router.go('/shiftsOverview/')}>
                {Translate.get('shift.section')}
              </Menu.Item>
            </Menu.SubMenu>
          }
        </Menu>
      </Col>

      <Col flex="none">
        <div className="user-section">
          {props.allowTenantChange && !props.isMobile &&
            <div
              className="button"
              onClick={props.selectActiveTenant}
            >
              <div className="icon tenant" />
              <span>
                {props.identity.tenant ?
                  props.identity.tenantName :
                  Translate.get('tenant.select.all')}
              </span>
            </div>
          }

          <Dropdown overlay={userCtxMenu}>
            <div className="button">
              <div className="icon user" />
              <span>
                {props.identity.displayName ? props.identity.displayName : props.identity.login}
              </span>
            </div>
          </Dropdown>

          <Dropdown overlay={languageCtxMenu}>
            <div className="button">
              <div className="icon language" />
            </div>
          </Dropdown>
        </div>
      </Col>
    </Row>
  </div>
}

export default Header