import React, { Component } from 'react'
import Router from '../../../costra/Router.js'
import WsContainers from '../../../costra/WsContainers.js'
import Translate from '../../../costra/Translate.js'

import LoadingPlaceholder from '../../../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../../../utils/ErrorPlaceholder.js'

class AuthorizedGlobalRoles extends Component {

  constructor(props) {
    super(props)
    this.state = {
      authorizedEntities: null,
      error: null,
    }
    this.authorizedIdsCache = null
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.role.FetchAuthorizedGlobalRoles',
      _tenant: this.props.tenant,
      authorizedIds: this.authorizedIdsCache
    }).then((result) => {
      this.setState({authorizedEntities: result.items || []})
    }, () => {
      this.setState({error: 'error.load.roles.global.authorized'})
    })
  }

  componentDidMount() {
    this.authorizedIdsCache = this.props.data.get(this.props.path) || []
    this.load()
  }

  componentDidUpdate(prevProps) {
    var authorization = this.props.data.get(this.props.path) || []
    if (JSON.stringify(this.authorizedIdsCache) !== JSON.stringify(authorization)) {
      this.authorizedIdsCache = authorization
      this.load()
    }
  }

  removeAuthorizedEntity(entityId) {
    this.props.data.toggleIdInArray(this.props.path, entityId, false)
  }

  editRole(id, event) {
    event.stopPropagation()
    Router.go('/roles/' + id)
  }

  render() {
    if (this.state.error) {
      return <div className="doubleList"><ErrorPlaceholder error={this.state.error}/></div>
    }
    if (!this.state.authorizedEntities) {
      return <div className="doubleList"><LoadingPlaceholder/></div>
    }

    return <div className="doubleList">
      <div className="header">
        <div className="icon right"/>
        <span>{Translate.get('user.roles.global.assigned')}</span>
      </div>
      <ul>
        {this.state.authorizedEntities.map((item) => {
          var isEnabled = (!this.props.disabled && item.isAuthorized)
          return (this.authorizedIdsCache.some((id) => id.$oid === item._id.$oid) &&
            <li key={item._id.$oid}>
              <div className={'button' + (isEnabled ? '' : ' disabled')}
                   onClick={() => isEnabled && this.removeAuthorizedEntity(item._id)}>
                <div className="icon global"/>
                <span>
                  {item.name}
                </span>
              </div>
              {item.isAuthorized &&
                <div className="button">
                  <div className="icon edit editButton"
                       title={Translate.get('role.edit')}
                       onClick={(event) => this.editRole(item._id.$oid, event)}/>
                </div>
              }
            </li>
          )
        })}
      </ul>
    </div>
  }

}

export default AuthorizedGlobalRoles