import React, { Component } from 'react'
import { Form, Popover, Input, TimePicker, Space, Select } from 'antd'
import moment from 'moment'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'
import Translate from '../../costra/Translate.js'

import AuthorizationMembership from '../../entity/AuthorizationMembership.js'

import EditPageHeader from '../../utils/EditPageHeader.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormInput from '../../utils/FormInput.js'
import { notificationError, notificationSuccess } from '../../utils/Notification.js'

class CalendarEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      smsConfigs: null,
      dateSets: null,
      activeTab: 'calendar',
      timezones: null,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    this.endTimeOptions = [
      { label: Translate.get('calendar.shift.endTime.sameDay'), value: 0 },
      { label: Translate.get('calendar.shift.endTime.nextDay'), value: 1 },
      { label: Translate.get('calendar.shift.endTime.in2Days'), value: 2 },
      { label: Translate.get('calendar.shift.endTime.in3Days'), value: 3 },
    ]

    this.data = new EditData(this, 'item.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)
  }

  load() {
    this.setState({ item: null })

    WsContainers.action({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.time.GetAvailableTimezones'
    }).then((result) => {
      this.setState({ timezones: result.timezones || [] })
    }, () => {
      notificationError('error.load.timezones')
    })

    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.calendar.FetchCalendar',
      id: (this.props.id === 'new' ? undefined : this.props.id),
    }).then((result) => {
      this.setState({ item: result })

      WsContainers.transaction({
        _class: 'com.optimsys.costra.optimcall.shiftplanner.sms.config.FetchSmsConfigsInfo',
        tenant: result._tenant,
      }).then((result) => {
        this.setState({ smsConfigs: (result.items || []) })
      }, () => {
        notificationError('error.load.sms.configs')
      })

      WsContainers.transaction({
        _class: 'com.optimsys.costra.optimcall.shiftplanner.date.FetchDateSetsInfo',
        tenant: result._tenant,
      }).then((result) => {
        this.setState({ dateSets: (result.items || []) })
      }, () => {
        notificationError('error.load.date.sets')
      })
    }, () => {
      notificationError('error.load.calendar')
      Router.go('/calendars')
    })
  }

  apply() {
    var item = this.state.item
    if (item.shiftTime.end.plusDaysFromStart === 0 &&
      item.shiftTime.start.hour === item.shiftTime.end.hour &&
      item.shiftTime.start.minute === item.shiftTime.end.minute
    ) {
      notificationError('calendar.shift.zeroLength')
      return
    }
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.calendar.UpdateCalendar',
      item: item,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({ item: result })
      if (this.props.id !== result._id.$oid) {
        Router.go('/calendars/' + result._id.$oid)
      }
    }, () => {
      notificationError('error.store.calendars')
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.id !== this.props.id) {
      this.load()
    }
  }

  render() {
    if (!this.state.item || !this.state.smsConfigs || !this.state.dateSets || !this.state.timezones) {
      return <div className="content"><LoadingPlaceholder /></div>
    }

    //const formItemLayout = {}
    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 8,
      },
    }

    const disableEditItems = !this.state.item._authorization.CalendarUpRem &&
      (this.props.id !== 'new' || !this.state.item._authorization.CalendarCreate)

    var smsConfigOptions = this.state.smsConfigs.map(smsConfig => {
      return {
        label: smsConfig.displayName,
        value: smsConfig.id.$oid,
      }
    }).sort((item1, item2) => {
      let normalizedLabel1 = item1.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      let normalizedLabel2 = item2.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      if (normalizedLabel1 === normalizedLabel2) return 0
      return normalizedLabel1 < normalizedLabel2 ? -1 : 1
    })
    smsConfigOptions.unshift({ label: Translate.get('value.not.defined'), value: '-' })

    var dateSetOptions = this.state.dateSets.map(dateSet => {
      return {
        label: dateSet.displayName,
        value: dateSet.id.$oid,
      }
    }).sort((item1, item2) => {
      let normalizedLabel1 = item1.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      let normalizedLabel2 = item2.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      if (normalizedLabel1 === normalizedLabel2) return 0
      return normalizedLabel1 < normalizedLabel2 ? -1 : 1
    })
    dateSetOptions.unshift({ label: Translate.get('value.not.defined'), value: '-' })

    const workerDisplayNamePatternTooltip = <>
      <div>{Translate.get('calendar.workerDisplayNamePattern.prompt')}</div>
      <div>{Translate.get('calendar.workerDisplayNamePattern.prompt.firstName')}</div>
      <div>{Translate.get('calendar.workerDisplayNamePattern.prompt.familyName')}</div>
    </>

    const calendarInfoSection = (
      <div className="form-section">
        <Form {...formItemLayout}>

          <FormInput.Text
            data={this.data}
            title="calendar.name"
            path="displayName"
            disabled={disableEditItems}
          />

          <FormInput.Text
            data={this.data}
            title="calendar.apiId"
            path="apiId"
            disabled={disableEditItems}
          />

          <FormInput.Select
            data={this.data}
            title="calendar.shift.zoneId"
            path="shiftTime.zoneId"
            disabled={disableEditItems}
            options={this.state.timezones}
            showSearch
          />

          <Form.Item label={Translate.get('calendar.shift.startTime')}>
            <TimePicker
              style={{ width: '100px' }}
              allowClear={false}
              format="HH:mm"
              disabled={disableEditItems}
              value={moment().hours(this.data.get('shiftTime.start.hour')).minutes(this.data.get('shiftTime.start.minute'))}
              onChange={(value) => this.data.set('shiftTime.start', { hour: value.hours(), minute: value.minutes() })}
            />
          </Form.Item>

          <Form.Item label={Translate.get('calendar.shift.endTime')}>
            <Space>
              <TimePicker
                style={{ width: '100px' }}
                allowClear={false}
                format="HH:mm"
                disabled={disableEditItems}
                value={moment().hours(this.data.get('shiftTime.end.hour')).minutes(this.data.get('shiftTime.end.minute'))}
                onChange={(value) => {
                  this.data.set('shiftTime.end.hour', value.hours())
                  this.data.set('shiftTime.end.minute', value.minutes())
                }}
              />

              <Select
                style={{ width: '250px' }}
                disabled={disableEditItems}
                options={this.endTimeOptions}
                value={this.data.get('shiftTime.end.plusDaysFromStart')}
                onChange={(value) => this.data.set('shiftTime.end.plusDaysFromStart', value)}
              />
            </Space>
          </Form.Item>

          {/* <FormInput.TimePicker
            data={this.data}
            title="calendar.shift.start"
            path="shiftStart"
            minuteStep={15}
            disabled={disableEditItems}
            timezone={this.state.timezone}
          >
            <Select
              style={{ width: '300px' }}
              showSearch
              value={this.state.timezone}
              onChange={(value) => this.setState({ timezone: value })}
              options={this.state.timezones}
            />
          </FormInput.TimePicker> */}

          <FormInput.InputNumber
            data={this.data}
            title="calendar.numberOfWorkersPerShift"
            path="numberOfWorkersPerShift"
            min="1"
            step="1"
            disabled={disableEditItems}
          />

          <Form.Item
            label={Translate.get('calendar.workerDisplayNamePattern')}
            tooltip={workerDisplayNamePatternTooltip}
          >
            <Popover placement="top" content={workerDisplayNamePatternTooltip} trigger="hover">
              {disableEditItems ? (
                this.data.get('workerDisplayNamePattern')
              ) : (
                <Input
                  value={this.data.get('workerDisplayNamePattern')}
                  onChange={(event) => this.data.set('workerDisplayNamePattern', event.target.value)}
                />
              )}
            </Popover>
          </Form.Item>

          <FormInput.SelectOpt
            data={this.data}
            title="calendar.smsConfig"
            path="smsConfig"
            pathSuffix="$oid"
            undefinedValue="-"
            options={smsConfigOptions}
            disabled={disableEditItems}
          />

          <FormInput.SelectOpt
            data={this.data}
            title="calendar.publicHolidays"
            path="publicHolidays"
            pathSuffix="$oid"
            undefinedValue="-"
            options={dateSetOptions}
            disabled={disableEditItems}
          />
        </Form>
      </div>
    )

    const authorizationSection = (
      <div className="form-section">
        <AuthorizationMembership data={this.data}
          path="authorization"
          tenant={this.state.item._tenant}
          disabled={disableEditItems}
          disableGroups={true} />
      </div>
    )

    return (
      <div className="content-column">
        <EditPageHeader
          component={this}
          disabled={disableEditItems}
          icon="calendar"
          title={this.state.item.displayName}
          titleUnnamed="entity.unnamed"
          titleCreate="calendar.create"
          closePath="/calendars/"
          tabs={[
            { key: 'calendar', title: 'info.main' },
            { key: 'authorization', title: 'entity.authorization' },
          ]}
        />

        {this.state.activeTab === 'calendar' && calendarInfoSection}
        {this.state.activeTab === 'authorization' && authorizationSection}

      </div>
    )
  }

}

export default CalendarEdit