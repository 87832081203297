import React, { Component } from 'react'
import { Modal, Button } from 'antd'

import Dialogs from '../../costra/Dialogs.js'
import Translate from '../../costra/Translate.js'
import WsContainers from '../../costra/WsContainers.js'

import '../../css/dialog.css'

class RemoveDateSetDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      working: false,
      error: null,
      visible: true,
    }
    this.close = this.close.bind(this)
    this.removeItem = this.removeItem.bind(this)
  }

  close() {
    this.setState({
      visible: false
    }, () => {
      setTimeout(() => Dialogs.close(false), 250)
    })
  }

  removeItem() {
    this.setState({working: true})
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.date.RemoveDateSet',
      id: { $oid: this.props.item.key },
    }).then(() => {
      this.setState({
        visible: false
      }, () => {
        setTimeout(() => Dialogs.close(true), 250)
      })
    }, () => {
      this.setState({error: 'error.remove.named.date.time.set', working: false})
    })
  }

  render() {
    return (
      <Modal
        visible={this.state.visible}
        onCancel={this.close}
        footer={[
          <Button key="cancel" type={this.state.error ? 'primary' : 'default'} onClick={this.close}>{Translate.get('cancel.button')}</Button>,
          !this.state.error && <Button key="process" type="primary" loading={this.state.working} onClick={this.removeItem}>{Translate.get('ok.button')}</Button>
        ]}
      >
        {this.state.error
          ?
          Translate.get(this.state.error)
          :
          Translate.get('date.set.remove.prompt').replace(
            '%set%',
            this.props.item.name
          )
        }
      </Modal>
    )
  }
}

export default RemoveDateSetDialog