import React, { Component } from 'react'
import Router from '../../../costra/Router.js'
import WsContainers from '../../../costra/WsContainers.js'
import Translate from '../../../costra/Translate.js'

import LoadingPlaceholder from '../../../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../../../utils/ErrorPlaceholder.js'

class Users extends Component {

  constructor(props) {
    super(props)
    this.state = {
      users: null,
      error: null,
    }
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.user.FetchUsers',
      _tenant: this.props.tenant,
    }).then((result) => {
      if (result.items) {
        result.items.forEach((item) => {
          var instances = {}
          instances['ownership'] = item._id
          if (item.extensions && item.extensions.adv && item.extensions.adv.roleInstances) {
            item.extensions.adv.roleInstances.forEach((roleInstance) =>
              instances[roleInstance.roleId.$oid] = roleInstance.instanceId
            )
          }
          item['_instances'] = instances
        })
      }
      this.setState({users: result.items || []})
    }, () => {
      this.setState({error: 'error.load.users'})
    })
  }

  componentDidMount() {
    this.load()
  }

  compareWithSearchText(item) {
    return (
      item.extensions && item.extensions.adv && item.extensions.adv.displayName &&
        item.extensions.adv.displayName.toLowerCase().includes(this.props.searchText.toLowerCase())
    ) || (
      item.login &&
        item.login.toLowerCase().includes(this.props.searchText.toLowerCase())
    )
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder error={this.state.error}/>
    }
    if (!this.state.users) {
      return <LoadingPlaceholder/>
    }
    var authorization = this.props.data.get(this.props.path) || []

    return <ul>
      {this.state.users.map((item) =>
        item._instances && item._instances[this.props.selectedRole] &&
        !authorization.some((id) => id.$oid === item._instances[this.props.selectedRole].$oid) &&
        this.compareWithSearchText(item) &&
        <li key={item._id.$oid}>

          <div className="button"
               onClick={() => this.props.data.toggleIdInArray(this.props.path, item._instances[this.props.selectedRole], true)}>
            <div className="icon user"/>
            <span>
              {item.extensions.adv.displayName ? item.extensions.adv.displayName : item.login}
            </span>
          </div>
          {!this.props.disabled &&
            <div className="button"
                 title={Translate.get('user.edit')}
                 onClick={(event) => Router.go('/users/' + item._id.$oid)}>
              <div className="icon edit"/>
            </div>
          }
        </li>
      )}
    </ul>
  }

}

export default Users