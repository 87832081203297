import React, { Component } from 'react'
import { Form } from 'antd'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'

import EditPageHeader from '../../utils/EditPageHeader.js'
import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormInput from '../../utils/FormInput.js'
import { notificationError, notificationSuccess } from '../../utils/Notification.js'
import AuthorizationMembership from '../../entity/AuthorizationMembership.js'

class WorkerEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      calendarsInfo: null,
      activeTab: 'worker',
    }

    this.data = new EditData(this, 'item.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)
  }

  load() {
    this.setState({ item: null })
    WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.worker.FetchWorker',
      id: (this.props.id === 'new' ? undefined : this.props.id),
    }).then((result) => {
      this.setState({ item: result })

      return WsContainers.transaction({
        _class: 'com.optimsys.costra.optimcall.shiftplanner.calendar.FetchCalendarsInfo',
        tenant : result._tenant,
      }).then((result) => {
        this.setState({ calendarsInfo: result.items || [] })
      }, () => {
        notificationError('error.load.calendars')
      })
    }, () => {
      notificationError('error.load.worker')
      Router.go('/workers')
    })


  }

  apply() {
    var item = this.state.item
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.worker.UpdateWorker',
      item: item,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({ item: result })
      if (this.props.id !== result._id.$oid) {
        Router.go('/workers/' + result._id.$oid)
      }
    }, () => {
      notificationError('error.store.worker')
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.id !== this.props.id) {
      this.load()
    }
  }

  render() {
    if (!this.state.item || !this.state.calendarsInfo) {
      return <div className="content"><LoadingPlaceholder /></div>
    }

    var disableEditItems = !this.state.item._authorization.WorkerUpRem &&
      (this.props.id !== 'new' || !this.state.item._authorization.WorkerCreate)

    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 8,
      },
    }

    const calendarOptions = this.state.calendarsInfo.map(calendar => {
      return {
        label: calendar.displayName,
        value: calendar.id.$oid
      }
    }).sort((item1, item2) => {
      let normalizedLabel1 = item1.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      let normalizedLabel2 = item2.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      if (normalizedLabel1 === normalizedLabel2) return 0
      return normalizedLabel1 < normalizedLabel2 ? -1 : 1
    })

    const workerInfoSection = (
      <div className="form-section">
        <Form {...formItemLayout}>
          <FormInput.Text
            data={this.data}
            title="worker.firstName"
            path="firstName"
            disabled={disableEditItems}
          />

          <FormInput.Text
            data={this.data}
            title="worker.familyName"
            path="familyName"
            disabled={disableEditItems}
          />

          <FormInput.Text
            data={this.data}
            title="worker.phoneNumberToDistribute"
            path="phoneNumberToDistribute"
            disabled={disableEditItems}
          />

          <FormInput.Text
            data={this.data}
            title="worker.phoneNumberToReceiveSms"
            path="phoneNumberToReceiveSms"
            disabled={disableEditItems}
          />

          <FormInput.SelectMulti
            data={this.data}
            title="worker.accessibleCalendars"
            path="accessibleCalendars"
            pathSuffix="$oid"
            disabled={disableEditItems}
            options={calendarOptions}
          />
        </Form>
      </div>
    )

    const authorizationSection = (
      <div className="form-section">
        <AuthorizationMembership data={this.data}
          path="authorization"
          tenant={this.state.item._tenant}
          disabled={disableEditItems}
          disableGroups={true} />
      </div>
    )

    return (
      <div className="content-column">
        <EditPageHeader
          component={this}
          disabled={disableEditItems}
          icon="worker"
          title={this.state.item.fullName}
          titleUnnamed="entity.unnamed"
          titleCreate="worker.create"
          closePath="/workers/"
          tabs={[
            { key: 'worker', title: 'info.main' },
            { key: 'authorization', title: 'entity.authorization' },
          ]}
        />

        {this.state.activeTab === 'worker' && workerInfoSection}
        {this.state.activeTab === 'authorization' && authorizationSection}
      </div>
    )
  }

}

export default WorkerEdit