import React, { Component } from 'react'
import { Form } from 'antd'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'
import Translate from '../../costra/Translate.js'

import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormSection from '../../utils/FormSection.js'
import EditControls from '../../utils/EditControls.js'
import FormInput from '../../utils/FormInput.js'
import { notificationError, notificationSuccess } from '../../utils/Notification.js'

import Device from './Device.js'
import Ports from './Ports.js'

class DeviceEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      storedLogin: null,
      storedPassword: null,
      ports: null,
      serviceIds: null,
      devicesToSelect : null,
      expandedSections: {
        device: true,
        rights: true,
        ports: true,
        //authorization: true,
        //rightsOverview: true,
      },
    }

    this.ports = new Ports(this)
    this.data = new EditData(this, 'item.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)
  }

  load() {
    if (this.props.id !== 'new') {
      this.setState({
        item: null,
        ports: null,
        serviceIds: null,
      })
      return WsContainers.action({
        _class: 'com.optimsys.costra.optimcall.shiftplanner.device.FetchDevice',
        id: this.props.id,
      }).then((result) => {
        if (!result.item.serviceId) {
          result.item.serviceId = 'undefined'
        }
        this.setState({
          item: result.item,
          storedLogin: result.item.login,
          storedPassword: result.item.password,
          ports: result.ports || [],
          serviceIds: result.serviceIds || [],
        })
      }, () => {
        notificationError('error.load.device')
        Router.go('/devices')
      })
    }
    else {
      return WsContainers.action({
        _class: 'com.optimsys.costra.optimcall.shiftplanner.device.GetNewDeviceObjects',
      }).then((result) => {
        this.setState({
          devicesToSelect: result.newDevices || [],
        })
      }, () => {
        notificationError('error.load.device')
        Router.go('/devices')
      })
    }
  }

  apply() {
    var item = this.state.item
    var ports = this.state.ports
    return WsContainers.action({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.device.UpdateDevice',
      item: item,
      ports: ports,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({
        item: result.item,
        storedLogin: result.item.login,
        storedPassword: result.item.password,
        ports: result.ports || [],
        serviceIds: result.serviceIds || [],
      })
      if (this.props.id !== result.item._id.$oid) {
        Router.go('/devices/' + result.item._id.$oid)
      }
    }, () => {
      notificationError('error.store.device')
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.id !== this.props.id) {
      this.load()
    }
  }

  createDevice(device) {
    this.setState({
      item: device.device,
      ports: [],
      serviceIds: device.serviceIds || [],
    })
  }

  hasPorts() {
    if (!this.state.item) {
      return false
    }
    switch(this.state.item._class) {
      case 'com.optimsys.costra.optimcall.device.SmsNeogateGateway':
      case 'com.optimsys.costra.optimcall.device.SmsSmppGateway':
      case 'com.optimsys.costra.optimcall.device.SmsSmsbranaGateway':
      case 'com.optimsys.costra.optimcall.device.SmsOdorikGateway':
        return true
      default:
        return false
    }
  }

  render() {
    if (!this.state.item) {
      if (this.props.id === 'new') {
        return <div className="dialogOverlay">
          <div className="dialogFrame">
            <div className="title">
              <label>{Translate.get('device.type.select.prompt') + ':'}</label>
            </div>
            <div className="formBlock">
              {this.state.devicesToSelect ?
                <select title={Translate.get('device.type')}
                        onChange={(event) => this.createDevice(this.state.devicesToSelect[event.target.value])}>
                  <option value={null}>{Translate.get('device.type.select')}</option>
                  {this.state.devicesToSelect.map((device, index) =>
                    <option value={index} key={index}>{Translate.get(device.device._class)}</option>
                  )}
                </select>
                :
                <LoadingPlaceholder/>
              }
            </div>
            <div className="dialogControls">
              <div className="button right"
                   onClick={() => Router.go('/devices')}>
                {Translate.get("device.section.back")}
              </div>
            </div>
          </div>
        </div>
      }
      return <div className="content"><LoadingPlaceholder/></div>
    }

    const disableEditItems = !this.state.item._authorization.DeviceUpRem &&
      (this.props.id !== 'new' || !this.state.item._authorization.DeviceCreate)

    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 8,
      },
    }

    return <div>
      <div className="content">

        <FormSection name="device"
                     title="device.title"
                     icon="device"
                     component={this}>
          <div className="content">

            <Form {...formItemLayout}>
              <FormInput.Text data={this.data}
                              title="device.displayName"
                              path="displayName"
                              disabled={disableEditItems}/>
              <FormInput.Check data={this.data}
                              title="device.isEnabled"
                              path="isEnabled"
                              disabled={disableEditItems}/>

              <Device class={this.state.item._class}
                      data={this.data}
                      item={this.state.item}
                      storedLogin={this.state.storedLogin}
                      storedPassword={this.state.storedPassword}
                      serviceIds={this.state.serviceIds}
                      disabled={disableEditItems}/>
            </Form>

          </div>
        </FormSection>

        {this.hasPorts() &&
          <FormSection name="ports"
                       title="device.ports"
                       icon="port"
                       component={this}>
            {this.ports.render(disableEditItems)}
          </FormSection>
        }


      </div>
      <EditControls component={this}
                    returnPath="/devices"
                    title={this.state.item.displayName}
                    icon="device"
                    disabled={disableEditItems}/>
    </div>
  }

}

export default DeviceEdit