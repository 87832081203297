import React, { Component } from 'react'
import { Select, InputNumber } from 'antd'
import { format, addMonths, setMonth, setYear, subMonths } from 'date-fns'
import SimpleListContainer from '../costra/SimpleListContainer.js'
import Translate from '../costra/Translate.js'
import InfoBox from '../utils/InfoBox.js'

import Calendar from './Calendar.js'

import "../css/shiftplanner.css";

class Container extends SimpleListContainer {
  params() {
    var params = super.params()
    params['calendar'] = this.component.state.selectedCalendar ? this.component.state.selectedCalendar._id.$oid : null
    return params
  }
}

class ShiftPlanner extends Component {
  constructor(props) {
    super(props)

    var state = SimpleListContainer.initialState()
    state.calendars = []
    state.workers = null
    state.currentMonth = new Date()
    state.selectedCalendar = null
    this.state = state

    this.container = new Container(this, 'com.optimsys.costra.optimcall.shiftplanner.calendar.CalendarsWithWorkers', () => {
      this.setState({ error: 'error.load.calendars' })
    })
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  renderMobileCalendarSelection() {
    return this.state.calendars && this.state.calendars.length > 1 && <div className="mobile-calendar-select">
      <Select
        onChange={(value) => this.setState({ selectedCalendar: this.state.calendars.find(cal => cal._id.$oid === value) }, () => this.container.reload())}
        value={this.state.selectedCalendar._id.$oid}
      >
        {this.state.calendars && this.state.calendars.map(item => {
          return <Select.Option key={item._id.$oid} value={item._id.$oid}>{item.displayName}</Select.Option>
        })}
      </Select>
    </div>
  }

  renderHeader() {
    return <div className={"left-mid-row" + (this.props.isMobile ? " mobile" : "")}>
      {!this.props.isMobile && this.state.calendars && this.state.calendars.length === 1 &&
        <div className="calendar-name">
          {this.state.calendars[0].displayName}
        </div>
      }
      {!this.props.isMobile && this.state.calendars && this.state.calendars.length > 1 && <div className="calendar-select">
        <Select
          onChange={(value) => this.setState({ selectedCalendar: this.state.calendars.find(cal => cal._id.$oid === value) }, () => this.container.reload())}
          value={this.state.selectedCalendar._id.$oid}
        >
          {this.state.calendars && this.state.calendars.map(item => {
            return <Select.Option key={item._id.$oid} value={item._id.$oid}>{item.displayName}</Select.Option>
          })}
        </Select>
      </div>}

      <div className="mid">
        <div className="col-start">
          <div className="month icon" onClick={this.prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="col-center">
          <Select
            onChange={(value) => this.setMonth(value)}
            value={Translate.get('calendar.months')[this.state.currentMonth.getMonth()].substr(0, 3)}
          >
            {Translate.get('calendar.months').map((month, index) => {
              return <Select.Option key={index} value={index}>{month}</Select.Option>
            })}
          </Select>
          <InputNumber min={1970} max={2999} value={format(this.state.currentMonth, "yyyy")} onChange={(value) => this.setYear(value)} />
        </div>
        <div className="col-end" onClick={this.nextMonth}>
          <div className="month icon">chevron_right</div>
        </div>
      </div>
    </div>
  }

  nextMonth = () => {
    this.setState({
      currentMonth: addMonths(this.state.currentMonth, 1)
    });
  };

  setMonth = (month) => {
    this.setState({
      currentMonth: setMonth(this.state.currentMonth, month)
    });
  };

  setYear = (year) => {
    this.setState({
      currentMonth: setYear(this.state.currentMonth, year)
    });
  };

  prevMonth = () => {
    this.setState({
      currentMonth: subMonths(this.state.currentMonth, 1)
    });

  };

  calculateDisplayName = (worker, displayNamePattern) => {
    return displayNamePattern.replaceAll('%firstName%', worker.firstName || '').replaceAll('%familyName%', worker.familyName || '')
  }

  render() {
    if (this.state.calendars && this.state.calendars.length === 0) {
      return <div className="content">
        <InfoBox text="no.calendar.message" />
      </div>
    }

    const workers = ([...this.state.workers]).map(worker => {
      return {
        label: this.calculateDisplayName(worker, this.state.selectedCalendar.workerDisplayNamePattern || '%firstName% %familyName%'),
        value: worker._id.$oid
      }
    }).sort((worker1, worker2) => {
      // removing diacritics for proper sorting
      let normalizedLabel1 = worker1.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      let normalizedLabel2 = worker2.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      if (normalizedLabel1 === normalizedLabel2) return 0
      return normalizedLabel1 < normalizedLabel2 ? -1 : 1
    })

    workers.unshift({ label: Translate.get('shift.unset.option'), value: "000000000000000000000000" })

    return (
      <div className="content">
        <div className={"shift-planner" + (this.props.isMobile ? " mobile" : '')}>
          {this.props.isMobile && this.renderMobileCalendarSelection()}
          {this.renderHeader()}
          <Calendar
            workers={workers}
            assigneeOfBulkEditing={this.state.assigneeOfBulkEditing}
            isMobile={this.props.isMobile}
            calendars={this.state.calendars}
            currentMonth={this.state.currentMonth}
            selectedCalendar={this.state.selectedCalendar}
          />
          {this.props.isMobile && this.renderHeader()}
        </div>
      </div>
    )

  }
}

export default ShiftPlanner