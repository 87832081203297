import React, { Component } from 'react'
import { Form } from 'antd'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'

import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormSection from '../../utils/FormSection.js'
import EditControls from '../../utils/EditControls.js'
import FormInput from '../../utils/FormInput.js'
import { notificationError, notificationSuccess } from '../../utils/Notification.js'

import Rights from './Rights.js'

class RoleEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      expandedSections: {
        role: true,
        rights: true,
        //authorization: true,
        //rightsOverview: true,
      },
    }

    this.data = new EditData(this, 'item.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)
  }

  load() {
    this.setState({ item: null })
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.role.FetchRole',
      id: (this.props.id === 'new' ? undefined : this.props.id),
    }).then((result) => {
      this.setState({item: result})
    }, () => {
      notificationError('error.load.role')
      Router.go('/roles')
    })
  }

  apply() {
    var item = this.state.item
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.role.UpdateRole',
      item: item,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({item: result})
      if (this.props.id !== result._id.$oid) {
        Router.go('/roles/' + result._id.$oid)
      }
    }, () => {
      notificationError('error.store.role')
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.id !== this.props.id) {
      this.load()
    }
  }

  render() {
    if (!this.state.item) {
      return <div className="content"><LoadingPlaceholder/></div>
    }
    const disableEditItems = !this.state.item._authorization.RoleUpRem &&
      (this.props.id !== 'new' || !this.state.item._authorization.RoleCreate)

    const disableAssignRights = !this.state.item._authorization.RoleAssignRights &&
      (this.props.id !== 'new' || !this.state.item._authorization.RoleCreate)

    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 8,
      },
    }

    return <div>
      <div className="content">

        <FormSection name="role"
                     title="role.title"
                     icon="role"
                     component={this}>
          <div className="content">
            <Form {...formItemLayout}>
              <FormInput.Text data={this.data}
                              title="role.name"
                              path="name"
                              disabled={disableEditItems}/>

              <FormInput.Check data={this.data}
                              title="role.isGlobal"
                              path="isGlobal"
                              disabled={disableEditItems}/>
            </Form>
          </div>
        </FormSection>

        <FormSection name="rights"
                     title="role.rights"
                     icon="right"
                     component={this}>
          <div className="content">
            <Rights data={this.data}
                    disabled={disableAssignRights}/>
          </div>
        </FormSection>

      </div>
      <EditControls component={this}
                    returnPath="/roles"
                    title={this.state.item.name}
                    icon="role"
                    disabled={disableEditItems && !this.state.item._authorization.RoleAssignRights}/>
    </div>
  }

}

export default RoleEdit