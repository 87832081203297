import React, { Component } from 'react'
import Dialogs from '../../costra/Dialogs.js'
import Translate from '../../costra/Translate.js'
import WsContainers from '../../costra/WsContainers.js'

import DialogFrame from '../../utils/DialogFrame.js'
import DialogErrorPlaceholder from '../../utils/DialogErrorPlaceholder.js'
import DialogLoadingPlaceholder from '../../utils/DialogLoadingPlaceholder.js'

import '../../css/dialog.css'

class RemoveApiKeyDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      working: false,
      error: null,
    }
    this.close = this.close.bind(this)
    this.removeItem = this.removeItem.bind(this)
  }

  close() {
    Dialogs.close(false)
  }

  removeItem() {
    this.setState({working: true})
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.api.RemoveApiKey',
      id: this.props.item._id,
    }).then(() => {
      Dialogs.close(true)
    }, () => {
      this.setState({error: 'error.remove.api.key'})
    })
  }

  render() {
    if (this.state.error) {
      return <DialogErrorPlaceholder error={this.state.error}/>
    }
    if (this.state.working) {
      return <DialogLoadingPlaceholder/>
    }
    return <DialogFrame>
      <div>
        {Translate.get('api.key.remove.prompt').replace('%key%', this.props.item.displayName)}
      </div>
      <div className="dialogControls">
        <div className="button right"
             title={Translate.get('no.button')}
             onClick={this.close}>
          {Translate.get('no.button')}
        </div>
        <div className="button right"
             title={Translate.get('yes.button')}
             onClick={this.removeItem}>
          {Translate.get('yes.button')}
        </div>
      </div>
    </DialogFrame>
  }
}

export default RemoveApiKeyDialog