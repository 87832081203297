import React from 'react';
import Translate from './costra/Translate.js'

import './css/login.css'

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errorEncountered: null,
    }
    this.loginFormSubmit = this.loginFormSubmit.bind(this)
  }

  loginFormSubmit(event) {
    event.preventDefault()
    this.setState({isLoading: true, errorEncountered: null})
    this.props.loginProc(event.target.login.value, event.target.password.value).then(result => {
      if (result.failed) {
        this.setState({isLoading: false, errorEncountered: 'Invalid credentials'})
      }
    }, (error) => this.setState({isLoading: false, errorEncountered: error}))
  }

  render() {
    if (this.isLoading) {
      return <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        Loading...
      </div>
    }
    return <div className="loginScreen">
      <div>
        <div className="loginTitle">Shift planner</div>
        <form onSubmit={this.loginFormSubmit} className="loginForm">
          {this.state.errorEncountered && <p className="error">{this.state.errorEncountered}</p>}
          <input name="login" autoFocus type="text" placeholder={Translate.get('user.login')} required/>
          <input name="password" type="password" placeholder={Translate.get('user.password')} required/>
          <input type="submit" className="button" value={Translate.get('user.logIn')}/>
        </form>
      </div>
    </div>
  }
}

export default Login