import React, { Component } from 'react'
import Translate from '../../costra/Translate.js'
import WsContainers from '../../costra/WsContainers.js'

import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../../utils/ErrorPlaceholder.js'

import '../../css/list.css'

class Rights extends Component {

  constructor(props) {
    super(props)
    this.state = {
      rights: null,
      error: null,
    }
  }

  load() {
    WsContainers.action({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.rights.GetRightsList'
    }).then((result) => {
      if (result.rights) {
        var selectedRights = this.props.data.get('rights')
        result.rights.forEach((item) => {
          if (selectedRights) {
            item['isMember'] = selectedRights.some((right) => item.right === right._class)
          }
          else {
            item['isMember'] = false
          }
          this.initializeMembership(item, selectedRights)
        })
      }
      else {
        result.items = []
      }
      this.setState({ rights: result.rights })
    }, () => {
      this.setState({error: 'error.load.rights'})
    })
  }

  initializeMembership(right, selectedRights) {
    right.children && right.children.forEach(item => {
      if (selectedRights) {
        item['isMember'] = selectedRights.some((right) => item.right === right._class)
      }
      else {
        item['isMember'] = false
      }
      this.initializeMembership(item, selectedRights)
    })
  }

  componentDidMount() {
    this.load()
  }

  setRight(item, value) {
    var selectedRights = this.props.data.get('rights')
    if (value) {
      item['isMember'] = true
      if (selectedRights) {
        selectedRights.push({ _class: item.right })
        this.props.data.set('rights', selectedRights)
      } else {
        this.props.data.set('rights', Array({ _class: item.right }))
      }
    }
    else {
      item['isMember'] = false
      if (selectedRights) {
        selectedRights.splice(selectedRights.findIndex(right =>  right._class === item.right), 1)
        this.props.data.set('rights', selectedRights)
      }
    }
  }

  renderRight(item) {
    return <li  key={item.right}>
      <div className={'row ' + (this.props.disabled ? '' : ' withHover') + (item['isMember'] ? ' enabled' : ' disabled')}
           onClick={(event) => !this.props.disabled && this.setRight(item, !item['isMember'])}>
        <div className={'icon ' + (item.isGlobal ? 'global' : 'right')}/>
        <div className="size5">
          {Translate.get(item.right)}
        </div>
        <div>
          {Translate.get(item.right + '.note')}
        </div>
      </div>

      {item.children &&
        item['isMember'] &&
        <ul className="list">
           {item.children.map((child) =>
            this.renderRight(child)
          )}
        </ul>
      }

    </li>
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder error={this.state.error}/>
    }
    if (!this.state.rights) {
      return <LoadingPlaceholder/>
    }
    var isRoleGlobal = this.props.data.get('isGlobal')

    return <div>
      <ul className="list">
        {this.state.rights && this.state.rights.map((right, index) =>
          (isRoleGlobal || !right.isGlobal) && this.renderRight(right)
        )}
      </ul>
    </div>
  }

}

export default Rights