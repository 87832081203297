import React from 'react'
import Translate from '../costra/Translate.js'
import Router from '../costra/Router.js'

function ErrorPlaceholder(props) {
  return (
    <div className="errorPlaceholder">
      <div className="icon error"/>
      <span>{Translate.get(props.error)}</span>
      {props.backButtonTitle && props.backButtonPath &&
        <div className="button left"
             title={Translate.get(props.backButtonTitle)}
             onClick={(event) => Router.go(props.backButtonPath)}>
          <span>{Translate.get(props.backButtonTitle)}</span>
        </div>
      }
    </div>
  )
}

export default ErrorPlaceholder