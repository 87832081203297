import React from 'react'
import Translate from '../costra/Translate.js'

import '../css/controls.css'

function itemsPerPageSelector(props) {
  return <select value={props.component.state.itemsPerPage}
                 title={Translate.get('page.itemsPerPage')}
                 onChange={(event) => props.container.setItemsPerPage(event.target.value)}>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
    <option value="250">250</option>
  </select>
}

function searchField(props) {
  if (props.withSearch) {
    return <>
      <input type="text"
             value={props.component.state.searchedText}
             title={Translate.get('page.search')}
             placeholder={Translate.get('page.search') + '...'}
             onChange={(event) => props.component.setState({searchedText : event.target.value})}
             onKeyPress={(event) => { if (event.key === 'Enter') props.container.search() }}/>
      <div className="button left"
           title={Translate.get('entity.search')}
           onClick={(event) => props.container.search()}>
        <div className="icon search"/>
      </div>
    </>
  }
  else {
    return <></>
  }
}

function ListControls(props) {
  if (props.component.state.pages <= 1) {
    return <div className={'controls' + (props.float ? ' float' : '')}>
      {searchField(props)}
      {itemsPerPageSelector(props)}
      {props.children}
    </div>
  }

  return <div className={'controls' + (props.float ? ' float' : '')}>

    {searchField(props)}

    <div className="button left"
         title={Translate.get('page.first.button')}
         onClick={(event) => props.container.setPage(1)}>
      <div className="icon doubleLeft"/>
    </div>
    <div className="button left"
         title={Translate.get('page.prev.button')}
         onClick={(event) => props.container.setPage(props.component.state.page - 1)}>
      <div className="icon arrowLeft"/>
    </div>
    <div className="pages">
      <span>{props.component.state.page}/{props.component.state.pages}</span>
    </div>
    <div className="button left"
         title={Translate.get('page.next.button')}
         onClick={(event) => props.container.setPage(props.component.state.page + 1)}>
      <div className="icon arrowRight"/>
    </div>
    <div className="button left"
         title={Translate.get('page.last.button')}
         onClick={(event) => props.container.setPage(props.component.state.pages)}>
      <div className="icon doubleRight"/>
    </div>

    {itemsPerPageSelector(props)}
    {props.children}
  </div>
}

export default ListControls