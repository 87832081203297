import React, { Component } from 'react'
import { Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import WsContainers from './costra/WsContainers.js'
import Translate from './costra/Translate.js'
import AppWithIdentity from './AppWithIdentity.js'

import 'antd/dist/antd.min.css'
import './css/main.css'
import './css/icons.css'

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connected: false,
      translateLoaded: false,
    }
  }

  componentDidMount() {
    WsContainers.onStateChange((state) => {
      this.setState({ connected: state })
    })
    Translate.onStateChange((language, state) => {
      this.setState({ translateLoaded: state })
    })
    WsContainers.init()

    const appLanguageCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('appLanguage='))
    
    const language = appLanguageCookie && appLanguageCookie.split('=')[1]

    Translate.init(language || 'en', (language) => {
      return Translate.loadFile(language, '/lang/' + language + '.json')
    })
  }

  render() {
    if (!this.state.connected || !this.state.translateLoaded) {
      return <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 36, color: '#005aa0' }} />} />
      </Row>
    }
    return <AppWithIdentity />
  }
}

export default App