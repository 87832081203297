import React, { useRef } from 'react'
import { Table as AntTable } from 'antd'
import Translate from '../costra/Translate'

const Table = (props) => {

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView(true)

  return (
    <div className="scrollable" ref={myRef}>
      <AntTable
        style={{ padding: '20px 30px' }}
        size="small"
        columns={props.columns}
        dataSource={props.data}
        onRow={props.onRow}
        onHeaderRow={props.onHeaderRow}
        scroll={{
          scrollToFirstRowOnChange: true
        }}
        rowClassName={props.rowClassName}
        pagination={!props.disabledPagination && {
          showSizeChanger: true,
          showTotal: total => Translate.get('table.total').replace('%total%', total),
          current: props.component.state.page,
          total: props.component.state.recordsTotal,
          pageSize: props.component.state.itemsPerPage,
          pageSizeOptions: [50, 100, 250, 500],
          onChange: (page, pageSize) => {
            executeScroll()
            props.container.setPageWithItemsPerPage(page, pageSize)
          },
        }}
      />
    </div>
  )
}

export default Table