import React, { Component } from 'react'
import { Select, Input, Form, Tooltip, Row, Col, TimePicker, Popover, Divider, InputNumber, Space, Checkbox } from 'antd'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'
import moment from 'moment'
import { format } from 'date-fns'
import { cs, enGB } from 'date-fns/locale'

import WsContainers from '../../costra/WsContainers.js'
import EditData from '../../costra/EditData.js'
import Router from '../../costra/Router.js'
import Translate from '../../costra/Translate.js'

import LoadingPlaceholder from '../../utils/LoadingPlaceholder.js'
import FormSection from '../../utils/FormSection.js'
import EditControls from '../../utils/EditControls.js'
import FormInput from '../../utils/FormInput.js'
import { notificationError, notificationSuccess } from '../../utils/Notification.js'

function formatNow(dateTimeFormat, dateTimeLocale) {
  try {
    return format(new Date(), dateTimeFormat, { locale: getLocaleByString(dateTimeLocale), useAdditionalDayOfYearTokens: true })
  } catch (error) {
    console.error(error)
    return Translate.get('datetime.format.invalid')
  }
}

function getLocaleByString(dateTimeLocale) {
  switch (dateTimeLocale) {
    case 'en':
      return enGB

    case 'cs':
      return cs

    default:
      return enGB
  }
}

class SmsConfigEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      timezones: null,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      senders: null,
      mailSenders: null,
      selectedSenderName: null,
      expandedSections: {
        smsConfig: true,
      },
    }

    this.data = new EditData(this, 'item.')
    this.load = this.load.bind(this)
    this.apply = this.apply.bind(this)
  }

  load() {
    this.setState({ item: null })

    WsContainers.action({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.time.GetAvailableTimezones'
    }).then((result) => {
      this.setState({ timezones: result.timezones || [] })
    }, () => {
      notificationError('error.load.timezones')
    })

    WsContainers.action({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.device.GetMailSenders'
    }).then((result) => {
      this.setState({ mailSenders: result.senders || [] })
    }, () => {
      notificationError('error.load.mailSenders')
    })

    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.sms.config.FetchSmsConfig',
      id: (this.props.id === 'new' ? undefined : this.props.id),
    }).then((result) => {
      WsContainers.action({
        _class: 'com.optimsys.costra.optimcall.shiftplanner.device.GetSmsSenders'
      }).then((sendersResult) => {
        let senders = sendersResult.senders || []
        if (senders.length === 0) {
          notificationError('error.no.sms.senders')
          Router.go('/smsconfigs')
        }
        else {
          //let filteredSenders = sendersResult.senders.filter(sender => sender.displayName)
          if (result.deviceToSend.$oid === "000000000000000000000000") {
            result.deviceToSend = { $oid: senders[0].deviceId.$oid }
          }
          this.setState({
            senders: senders.map(sender => { return { label: sender.displayName, value: sender.deviceId.$oid } }),
            item: result,
          })
        }
      }, () => {
        notificationError('error.load.sms.senders')
      })
    }, () => {
      notificationError('error.load.sms.config')
      Router.go('/smsconfigs')
    })
  }

  apply() {
    var item = this.state.item

    return WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.sms.config.UpdateSmsConfig',
      item: item,
    }).then((result) => {
      notificationSuccess('success.changes.apply')
      this.setState({ item: result })
      if (this.props.id !== result._id.$oid) {
        Router.go('/smsconfigs/' + result._id.$oid)
      }
    }, () => {
      notificationError('error.store.sms.config')
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.id !== this.props.id) {
      this.load()
    }
  }

  render() {
    if (!this.state.item || !this.state.senders || !this.state.timezones || !this.state.mailSenders) {
      return <div className="content"><LoadingPlaceholder /></div>
    }

    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 8,
      },
    }

    const mailSendersOptions = [{
      displayName: Translate.get('select.not.selected'),
      deviceId: { $oid: 'undefined' }
    }, ...this.state.mailSenders].map(mailSender => {
      return {
        label: mailSender.displayName,
        value: mailSender.deviceId.$oid,
      }
    })

    const msgTooltip = <>
      <div>{Translate.get('sms.template.prompt.empty')}</div>
      <div>{Translate.get('sms.template.prompt.options')}</div>
      <div>{Translate.get('sms.template.prompt.calendarName')}</div>
      <div>{Translate.get('sms.template.prompt.startTime')}</div>
      <div>{Translate.get('sms.template.prompt.endTime')}</div>
      <div>{Translate.get('sms.template.prompt.currentTime')}</div>
      <div>{Translate.get('sms.template.prompt.workerPosition')}</div>
    </>

    const msgTooltipWithoutStartEndTime = <>
      <div>{Translate.get('sms.template.prompt.empty')}</div>
      <div>{Translate.get('sms.template.prompt.options')}</div>
      <div>{Translate.get('sms.template.prompt.calendarName')}</div>
      <div>{Translate.get('sms.template.prompt.currentTime')}</div>
    </>

    const msgTooltipMail = <>
      <div>{Translate.get('sms.template.prompt.empty')}</div>
      <div>{Translate.get('sms.template.prompt.options')}</div>
      <div>{Translate.get('sms.template.prompt.calendarName')}</div>
      <div>{Translate.get('sms.template.prompt.currentTime')}</div>
      <div>{Translate.get('sms.template.prompt.smsText')}</div>
      <div>{Translate.get('sms.template.prompt.smsDst')}</div>
      <div>{Translate.get('sms.template.prompt.smsDstName')}</div>
    </>

    return <div>
      <div className="content">

        <FormSection name="smsConfig"
          title="sms.config.title"
          icon="sms"
          component={this}>
          <div className="content">
            <Form {...formItemLayout}>
              <FormInput.Text data={this.data}
                title="sms.config.displayName"
                path="displayName" />


              <Form.Item label={Translate.get('sms.config.sender')}>
                <Select
                  value={this.data.get('deviceToSend.$oid')}
                  onChange={(value) => this.data.set('deviceToSend.$oid', value)}
                  options={this.state.senders}
                />
              </Form.Item>

              <Divider orientation="left">{Translate.get('sms.config.dateTimeFormat.title')}</Divider>

              <Form.Item label={Translate.get('sms.config.zoneId')}>
                <Select
                  showSearch
                  value={this.data.get('zoneId')}
                  onChange={(value) => this.data.set('zoneId', value)}
                  options={this.state.timezones}
                />
              </Form.Item>

              <FormInput.Text
                data={this.data}
                title="sms.config.dateTimeFormat"
                path="dateTimeFormat"
                beforeInput={
                  <Tooltip title={Translate.get('sms.config.dateTimeLocale')} placement="left">
                    <Select
                      value={this.data.get('dateTimeLocale')}
                      onChange={(value) => this.data.set('dateTimeLocale', value)}
                      options={[
                        { label: Translate.get('language.en'), value: 'en' },
                        { label: Translate.get('language.cs'), value: 'cs' },
                      ]}
                    />
                  </Tooltip>
                }
                addonBefore={
                  <Select
                    value={Translate.get('format.suggestion.select')}
                    style={{ width: '200px' }}
                    dropdownStyle={{ textAlign: 'center' }}
                    options={[
                      { value: 'dd.MM.yyyy HH:mm' },
                      { value: 'dd/MM/yyyy HH:mm' },
                      { value: 'dd-MM-yyyy HH:mm' },
                      { value: 'yyyy-MM-dd HH:mm' },
                      { value: 'dd.MM.yyyy H:mm' },
                      { value: 'dd/MM/yyyy H:mm' },
                      { value: 'dd-MM-yyyy H:mm' },
                      { value: 'yyyy-MM-dd H:mm' },
                      { value: 'HH:mm' },
                      { value: 'H:mm'}
                    ]}
                    onChange={(value) => this.data.set('dateTimeFormat', value)}
                  />
                }
                addonAfter={
                  <Tooltip title={Translate.get('sms.config.dateTimeFormat.example')}>
                    {formatNow(this.data.get('dateTimeFormat'), this.data.get('dateTimeLocale'))}
                  </Tooltip>
                }
              />

              <Divider orientation="left">{Translate.get('sms.config.worker.title')}</Divider>

              <Form.Item
                label={Translate.get('sms.config.msgBeforeStart')}
                tooltip={msgTooltip}
              >
                <Row gutter={[16, 8]}>

                  <Col flex="auto">
                    <Popover placement="top" content={msgTooltip} trigger="focus">
                      <Input.TextArea
                        rows={2}
                        value={this.data.get('msgBeforeStart')}
                        onChange={(event) => this.data.set('msgBeforeStart', event.target.value)}
                      />
                    </Popover>
                  </Col>

                  <Col>
                    <Tooltip title={Translate.get('sms.config.notificationBeforeStartOffset')}>
                      <TimePicker
                        allowClear={false}
                        format="HH:mm"
                        style={{ width: '100px' }}
                        minuteStep={15}
                        value={moment().hours(this.data.get('notificationBeforeStartOffset.hours')).minutes(this.data.get('notificationBeforeStartOffset.minutes'))}
                        onChange={(value) => this.data.set('notificationBeforeStartOffset', { hours: value.hours(), minutes: value.minutes() })}
                      />
                    </Tooltip>
                  </Col>


                </Row>
              </Form.Item>

              <Form.Item
                label={Translate.get('sms.config.msgAtStart')}
                tooltip={msgTooltip}
              >
                <Popover placement="top" content={msgTooltip} trigger="focus">
                  <Input.TextArea
                    rows={2}
                    value={this.data.get('msgAtStart')}
                    onChange={(event) => this.data.set('msgAtStart', event.target.value)}
                  />
                </Popover>
              </Form.Item>

              <Form.Item
                label={Translate.get('sms.config.msgAtEnd')}
                tooltip={msgTooltip}
              >
                <Row gutter={[16, 0]}>
                  <Col flex="auto">
                    <Popover placement="top" content={msgTooltip} trigger="focus">
                      <Input.TextArea
                        rows={2}
                        value={this.data.get('msgAtEnd')}
                        onChange={(event) => this.data.set('msgAtEnd', event.target.value)}
                      />
                    </Popover>
                  </Col>
                  <Col>
                    <Tooltip title={Translate.get('sms.config.sendSmsAtEndIfAssignedToNextShift.prompt')}>
                      <Checkbox
                        checked={this.state.item.sendSmsAtEndIfAssignedToNextShift}
                        onChange={(event) => this.data.set('sendSmsAtEndIfAssignedToNextShift', event.target.checked)}
                      >
                        {Translate.get('sms.config.sendSmsAtEndIfAssignedToNextShift')}
                      </Checkbox>
                    </Tooltip>
                  </Col>
                </Row>


              </Form.Item>

              <Form.Item
                label={Translate.get('sms.config.msgShiftAssigned')}
                tooltip={msgTooltip}
              >
                <Popover placement="top" content={msgTooltip} trigger="focus">
                  <Input.TextArea
                    rows={2}
                    value={this.data.get('msgShiftAssigned')}
                    onChange={(event) => this.data.set('msgShiftAssigned', event.target.value)}
                  />
                </Popover>
              </Form.Item>

              <Form.Item
                label={Translate.get('sms.config.msgShiftUnassigned')}
                tooltip={msgTooltip}
              >
                <Popover placement="top" content={msgTooltip} trigger="focus">
                  <Input.TextArea
                    rows={2}
                    value={this.data.get('msgShiftUnassigned')}
                    onChange={(event) => this.data.set('msgShiftUnassigned', event.target.value)}
                  />
                </Popover>
              </Form.Item>

              <Divider orientation="left">{Translate.get('sms.config.sv.title')}</Divider>

              <Form.Item label={Translate.get('sms.config.sv.contacts')}>
                <Row gutter={[16, 0]}>
                  <Col flex="auto">
                    <Tooltip title={Translate.get('sms.config.sv.phone')}>
                      <Input
                        prefix={<PhoneOutlined />}
                        value={this.data.get('supervisorPhone')}
                        onChange={(event) => this.data.set('supervisorPhone', event.target.value)}
                      />
                    </Tooltip>
                  </Col>
                  <Col flex="auto">
                    <Tooltip title={Translate.get('sms.config.sv.mail')}>
                      <Input
                        prefix={<MailOutlined />}
                        value={this.data.get('supervisorMail')}
                        onChange={(event) => this.data.set('supervisorMail', event.target.value)}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item label={Translate.get('sms.config.sv.checkRange')}>
                <Row gutter={[16, 0]}>
                  <Col>
                    <Tooltip title={Translate.get('sms.config.sv.checkRange.checkNumberDaysAhead')}>
                      <InputNumber
                        value={this.data.get('checkNumberDaysAhead')}
                        min="1"
                        step="1"
                        onChange={(value) => this.data.set('checkNumberDaysAhead', value)}
                      />
                    </Tooltip>

                  </Col>
                  <Col>
                    <Tooltip placement="right" title={Translate.get('sms.config.sv.checkRange.timeOfDayCheck')}>
                      <Space>
                        <TimePicker
                          allowClear={false}
                          format="HH:mm"
                          style={{ width: '100px' }}
                          value={moment().hours(this.data.get('timeOfDayCheck.hour')).minutes(this.data.get('timeOfDayCheck.minute'))}
                          onChange={(value) => {
                            this.data.set('timeOfDayCheck.hour', value.hours())
                            this.data.set('timeOfDayCheck.minute', value.minutes())
                          }}
                        />
                        <Select
                          style={{width: '250px'}}
                          showSearch
                          value={this.data.get('timeOfDayCheck.zoneId')}
                          onChange={(value) => this.data.set('timeOfDayCheck.zoneId', value)}
                          options={this.state.timezones}
                        />
                      </Space>
                    </Tooltip>

                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                label={Translate.get('sms.config.msgIfNotSet')}
                tooltip={msgTooltipWithoutStartEndTime}
              >
                <Popover placement="top" content={msgTooltipWithoutStartEndTime} trigger="focus">
                  <Input.TextArea
                    rows={2}
                    value={this.data.get('msgIfNotSet')}
                    onChange={(event) => this.data.set('msgIfNotSet', event.target.value)}
                  />
                </Popover>
              </Form.Item>

              <Form.Item
                label={Translate.get('sms.config.msgIfWasUnset')}
                tooltip={msgTooltip}
              >
                <Popover placement="top" content={msgTooltip} trigger="focus">
                  <Input.TextArea
                    rows={2}
                    value={this.data.get('msgIfWasUnset')}
                    onChange={(event) => this.data.set('msgIfWasUnset', event.target.value)}
                  />
                </Popover>
              </Form.Item>

              <Divider orientation="left">{Translate.get('sms.config.mailSender.title')}</Divider>

              <Form.Item
                label={Translate.get('sms.config.mailSender')}
                tooltip={Translate.get('sms.config.mailSender.prompt')}
              >
                <Select
                  value={this.data.get('mailDevice') ? this.data.get('mailDevice.$oid') : 'undefined'}
                  onChange={(value) => (value === 'undefined') ? this.data.set('mailDevice', undefined) : this.data.set('mailDevice', { $oid: value })}
                  options={mailSendersOptions}
                />
              </Form.Item>

              <Form.Item
                label={Translate.get('sms.config.smsErrorMail')}
                tooltip={msgTooltipMail}
              >
                <Popover placement="top" content={msgTooltipMail} trigger="focus">
                  <Input.TextArea
                    rows={2}
                    value={this.data.get('smsErrorMail')}
                    onChange={(event) => this.data.set('smsErrorMail', event.target.value)}
                  />
                </Popover>
              </Form.Item>
            </Form>

          </div>
        </FormSection>

      </div>
      <EditControls component={this}
        returnPath="/smsconfigs"
        title={this.state.item.displayName}
        icon="sms" />
    </div>
  }

}

export default SmsConfigEdit