import React, { Component } from 'react'
import Router from '../../../costra/Router.js'
import WsContainers from '../../../costra/WsContainers.js'
import Translate from '../../../costra/Translate.js'

import LoadingPlaceholder from '../../../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../../../utils/ErrorPlaceholder.js'

class UnauthorizedGlobalRoles extends Component {

  constructor(props) {
    super(props)
    this.state = {
      roles: null,
      error: null,
    }
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.optimcall.shiftplanner.role.FetchGlobalRoles',
      _tenant: this.props.tenant,
    }).then((result) => {
      this.setState({
        roles: result.items || [],
      })
    }, () => {
      this.setState({error: 'error.load.roles'})
    })
  }

  componentDidMount() {
    this.load()
  }

  /*
  componentDidUpdate(prevProps) {
    var authorization = this.props.data.get(this.props.path) || []
    if (JSON.stringify(this.authorizedIdsCache) !== JSON.stringify(authorization)) {
      this.authorizedIdsCache = authorization
      var unauthorizedRoles = this.state.roles.filter(item =>
        !this.authorizedIdsCache.some(id => id.$oid === item._id.$oid)
      )
      this.setState({hidden: unauthorizedRoles.length === 0})
    }
  }
  */

  addAuthorizedId(id) {
    this.props.data.toggleIdInArray(this.props.path, id, true)
  }

  editRole(id, event) {
    event.stopPropagation()
    Router.go('/roles/' + id)
  }

  render() {
    if (this.state.error) {
      return <div className="doubleList"><ErrorPlaceholder error={this.state.error}/></div>
    }
    if (!this.state.roles) {
      return <div className="doubleList"><LoadingPlaceholder/></div>
    }

    var authorizedIds = this.props.data.get(this.props.path) || []
    return <div className="doubleList">
      <div className="header">
        <div className="icon authorization"/>
        <span>{Translate.get('user.roles.global.available')}</span>
      </div>

      <ul>
        {this.state.roles.map((item) =>
          !authorizedIds.some((id) => id.$oid === item._id.$oid) &&
            <li key={item._id.$oid}>
              <div className="button"
                   onClick={() => this.addAuthorizedId(item._id)}>
                <div className="icon global"/>
                <span>
                  {item.name}
                </span>
              </div>
              <div className="button">
                <div className="icon edit editButton"
                     title={Translate.get('role.edit')}
                     onClick={(event) => this.editRole(item._id.$oid, event)}/>
              </div>
            </li>
        )}
      </ul>
    </div>
  }
}

export default UnauthorizedGlobalRoles